import { chainId } from "../config";

export const backend_url = (() => {
  if (process.env.NODE_ENV === "production") {
    if (chainId === 97) {
      return "https://api.tinyworlds.io";
    }
    return "https://farmapi.tinyworlds.io";
  }
  if (chainId === 97) {
    return "";
    // return "http://ec2co-ecsel-iuoelrd6t8du-561022681.us-east-2.elb.amazonaws.com:3005";
  }
  return "http://alb-1-1066624506.ap-east-1.elb.amazonaws.com:3005";
  // return "";
})();
// console.log("backend_url", backend_url);

export const static_api_base = "/resources/data";

export const img_url = "//images.tinyworlds.io";

export const my_api_base = "/api/me";

export const farm_api_base = "/api/farm";

export const auction_api_base = "/api/auctions";

export const game_api_base = "/api/game";

export const nfts_api_base = "/api/nfts";
