// import BigNumber from 'bignumber.js'
import { Contract, BigNumber } from "ethers";
import Tiny from "src/tiny";
import ERC20 from "./ERC20";
import contractInfoMap from "./contractInfoMap";

import type { Farm } from "src/contexts/Farms/types";

// BigNumber.config({
//   EXPONENTIAL_AT: 1000,
//   DECIMAL_PLACES: 80,
// })

export const getWBNBContract = (tiny: Tiny) => {
  return tiny && tiny.externalTokens.BNB;
};
export const getBUSDContract = (tiny: Tiny) => {
  return tiny.externalTokens.BUSD;
};
export const getUSDTContract = (tiny: Tiny) => {
  return tiny.externalTokens.USDT;
};

export const getYieldFarmingContract = (tiny: Tiny) => {
  return tiny.contracts.YieldFarming;
};

export const getMulticallContract = (tiny: Tiny) => {
  return tiny.contracts.multicall;
};

export const getFarms: (tiny: Tiny) => Farm[] = (tiny) => {
  // console.log('tiny', tiny);
  return tiny.pools.map(
    ({
      pid,
      name,
      tokenSymbol,
      quoteTokenSymbol,
      lpAddress,
      lpContract,
      multiplying,
      icon,
    }) => ({
      pid,
      name,
      lpAddress,
      lpContract,
      tokenSymbol,
      quoteTokenSymbol,
      earnToken: "TINC",
      earnTokenAddress: tiny.externalTokens.TINC.address,
      multiplying,
      icon,
    })
  );
};

/**
 * 获取矿池权重
 */
export async function getPoolWeight(
  yieldFarmingContract: Contract,
  pid: number | string
) {
  const { allocPoint }: { allocPoint: BigNumber } =
    await yieldFarmingContract.poolInfo(pid);
  const totalAllocPoint: BigNumber =
    await yieldFarmingContract.totalAllocPoint();
  // console.log('allocPoint', allocPoint);
  // console.log('totalAllocPoint', totalAllocPoint);
  return allocPoint.mul(10000).div(totalAllocPoint).toNumber() / 10000;
}

// 这是计算单币池质押的 Token 的数量
export async function getSingleValue(
  yieldFarmingContract: Contract,
  pid: number | string,
  tiny: Tiny
) {
  const poolInfo = await yieldFarmingContract.poolInfo(pid);
  // const strategyAddress = poolInfo.strat;
  const strategyContract = tiny.contracts.farmStrategy.attach(poolInfo.strat);
  const wantLockedTotal: BigNumber = await strategyContract.wantLockedTotal();
  // console.log("getSingleValue wantLockedTotal", wantLockedTotal);
  return {
    tokenAmount: wantLockedTotal,
    poolWeight: await getPoolWeight(yieldFarmingContract, pid),
  };
}

export function getEarned(
  yieldFarmingContract: Contract,
  pid: number | string,
  account: string
): Promise<BigNumber> {
  return yieldFarmingContract.pendingReward(pid, account);
}

export const getTotalLPBaseTokenValue = async (
  yieldFarmingContract: Contract,
  baseToken: ERC20,
  lpContract: Contract,
  tokenContract: ERC20,
  pid: number | string,
  tiny: Tiny
) => {
  // Get balance of the token address
  // 这是 LP交易对 拥有的 Token 的量
  // console.log("pid, tokenContract", pid, tokenContract);
  // console.log("lpContract.address", lpContract.address);
  const tokenAmountWholeLP = await tokenContract.balanceOf(lpContract.address);
  // console.log("pid, tokenAmountWholeLP", pid, tokenAmountWholeLP);
  // 这是 LP交易对 拥有的锚定币的数量，一般是 BNB 的数量，可能是 BUSD、USDT
  const baseTokenWholeLP = await baseToken.balanceOf(lpContract.address);

  // console.log("baseToken", baseToken);
  // Get the share of lpContract that yieldFarmingContract owns
  const { tokenAmount: balance, poolWeight } = await getSingleValue(
    yieldFarmingContract,
    pid,
    tiny
  );

  // Convert that into the portion of total lpContract = p1
  // LP 矿池的总量
  const totalSupply: BigNumber = await lpContract.totalSupply();

  const coefficient = 1e8;

  const portionLp = balance.mul(coefficient).div(totalSupply); // 矿池锁定的 LP 占矿池所有 LP 的比例
  // Get total weth value for the lpContract = w1
  // console.log("portionLp", portionLp);
  const baseDecimal = baseToken.decimal;
  // console.log('baseDecimal:', baseDecimal);

  // console.log('lpContract.address', lpContract.address);
  // Return p1 * w1 * 2
  const totalLpBaseValue = portionLp.mul(baseTokenWholeLP).mul(2); // 锁定的 LP 的相对 锚定币 的价值
  // console.log('portionLp', portionLp.toNumber()) // 0.1

  // Calculate
  // 这是 LP 矿池拥有的 token 的数量
  const tokenAmount = tokenAmountWholeLP.mul(portionLp).div(coefficient);

  // 这是 LP 矿池拥有的 HT 的数量
  const baseTokenAmount = baseTokenWholeLP.mul(portionLp).div(coefficient);
  // console.log('tokenAmount', tokenAmount.toNumber())
  // console.log('wbnbAmount', wbnbAmount.toNumber())

  const tokenPriceInBase =
    baseTokenWholeLP
      .mul(coefficient)
      .div(tokenAmountWholeLP)
      .div(BigNumber.from(10).pow(baseDecimal))
      .toNumber() / coefficient;

  return {
    tokenAmount,
    baseTokenAmount,
    totalBaseValue:
      totalLpBaseValue.div(BigNumber.from(10).pow(baseDecimal)).toNumber() /
      coefficient,
    tokenPriceInBase,
    poolWeight,
  };
};

// export const getPairPrice = async (
//   lpContract,
//   token1Contract,
//   token2Contract
// ) => {
//   const token1AmountWhole = await token1Contract.methods
//     .balanceOf(lpContract.options.address)
//     .call();
//   const token2AmountWhole = await token2Contract.methods
//     .balanceOf(lpContract.options.address)
//     .call();
//   const token1Decimals = await token1Contract.methods.decimals().call();
//   const token2Decimals = await token2Contract.methods.decimals().call();

//   const token1Amount = new BigNumber(token1AmountWhole).div(
//     new BigNumber(10).pow(token1Decimals)
//   );
//   const token2Amount = new BigNumber(token2AmountWhole).div(
//     new BigNumber(10).pow(token2Decimals)
//   );
//   return token1Amount.div(token2Amount);
// };

// export const approve = async (lpContract, yieldFarmingContract, account) => {
//   return lpContract.methods
//     .approve(yieldFarmingContract.options.address, ethers.constants.MaxUint256)
//     .send({ from: account });
// };

// export const approveAddress = async (lpContract, address, account) => {
//   return lpContract.methods
//     .approve(address, ethers.constants.MaxUint256)
//     .send({ from: account });
// };

// const MIN_GAS_LIMIT = 150000;
// function getGasLimit(gas) {
//   const gasLimit1 = Math.floor(gas * 1.2);
//   return Math.max(gasLimit1, MIN_GAS_LIMIT);
// }

// export const stake = async (yieldFarmingContract, pid, amount, account) => {
//   const limit = await yieldFarmingContract.methods
//     .deposit(
//       pid,
//       new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()
//     )
//     .estimateGas({ from: account });

//   return yieldFarmingContract.methods
//     .deposit(
//       pid,
//       new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()
//     )
//     .send({ from: account, gas: getGasLimit(limit) })
//     .on("transactionHash", (tx) => {
//       console.log(tx);
//       return tx.transactionHash;
//     });
// };

// export const unstake = async (yieldFarmingContract, pid, amount, account) => {
//   const limit = await yieldFarmingContract.methods
//     .withdraw(
//       pid,
//       new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()
//     )
//     .estimateGas({ from: account });

//   return yieldFarmingContract.methods
//     .withdraw(
//       pid,
//       new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()
//     )
//     .send({ from: account, gas: getGasLimit(limit) })
//     .on("transactionHash", (tx) => {
//       console.log(tx);
//       return tx.transactionHash;
//     });
// };
// export const harvest = async (yieldFarmingContract, pid, account) => {
//   const limit = await yieldFarmingContract.methods
//     .deposit(pid, "0")
//     .estimateGas({ from: account });
//   console.log("limit", limit);
//   return yieldFarmingContract.methods
//     .deposit(pid, "0")
//     .send({ from: account, gas: getGasLimit(limit) })
//     .on("transactionHash", (tx) => {
//       console.log(tx);
//       return tx.transactionHash;
//     });
// };

export async function getStaked(
  yieldFarmingContract: Contract,
  pid: number,
  account: string
): Promise<BigNumber> {
  try {
    const amount = await yieldFarmingContract.stakedWantTokens(pid, account);
    return amount;
  } catch {
    return BigNumber.from(0);
  }
}
// _ownerflag, 目标英雄所在的位置：0--NFT在钱包地址，1--NFT在质押挖矿，2--NFT在LP挖矿，3--NFT在游戏中，默认0
export function getNFTOwnerflag(nftOwnerAddress: string) {
  const gatewayAddress = contractInfoMap.TinyGateway.address
  const farmAddress = contractInfoMap.TinyLPFarm.address;
  const squadAddress = contractInfoMap.TinyNFTFarm.address;
  if (nftOwnerAddress === squadAddress) {
    return 1;
  }
  if (nftOwnerAddress === farmAddress) {
    return 2;
  }
  if (gatewayAddress === nftOwnerAddress) {
    return 3;
  }
  return 0;
}
