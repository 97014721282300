import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  endAdornment?: React.ReactNode;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  startAdornment?: React.ReactNode;
  value?: number | string;
  type?: string;
  max?: number | string;
  min?: number | string;
}

const Input: React.FC<InputProps> = ({
  endAdornment,
  onChange,
  placeholder,
  startAdornment,
  value,
  type,
  min,
  max,
  ...rest
}) => {
  const numberInputProps = {
    max,
    min,
  };
  return (
    <StyledInputWrapper>
      {!!startAdornment && startAdornment}
      <StyledInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        {...numberInputProps}
        {...rest}
      />
      {!!endAdornment && endAdornment}
    </StyledInputWrapper>
  );
};

const StyledInputWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 2px ${(props) => props.theme.spacing[2]}px;
  width: 85%;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  border: 2px solid #514B45;
`;

const StyledInput = styled.input`
  background: none;
  border: 0;
  /* color: ${(props) => props.theme.color.yellow}; */
  font-size: 1.2rem;
  flex: 1;
  height: 2.5rem;
  margin: 0;
  padding: 0 10px 0 0;
  outline: none;
`;

export default Input;
