import { PropsWithChildren } from "react";
import styled from "styled-components";
import BaseButton, { BaseProps } from "./BaseButton";

import button_11_l from "src/images/button11_l.png";
import button_11_m from "src/images/button11_m.png";
import button_11_r from "src/images/button11_r.png";

export default function ParallelogramButton(
  props: PropsWithChildren<BaseProps>
) {
  const { children, disabled, ...rest } = props;

  return (
    <StyledButton size="sm" disabled={disabled} {...rest}>
      {children}
    </StyledButton>
  );
}

const StyledButton = styled(BaseButton)<BaseProps>`
  position: relative;
  min-width: 7rem;
  padding: 0.3rem 0.9rem 0.2rem 1.1rem;
  color: white;
  background-image: url(${button_11_m}), url(${button_11_l}),
    url(${button_11_r});
  background-size: calc(100% - 30px) 100%, 16px 100%, 16px 100%;
  background-position: center, left, right;
  text-align: center;
  line-height: 1.3;
  filter: ${(props) =>
    "drop-shadow(0 3px 0 #66666688)" + (props.disabled ? " grayscale(1)" : "")};
`;
