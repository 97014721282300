import React, { CSSProperties } from "react";
import styled from "styled-components";

interface ContainerProps {
  className?: string;
  children?: React.ReactNode;
  size?: "es" | "sm" | "md" | "lg";
  style?: CSSProperties;
}

const Container: React.FC<ContainerProps> = ({
  children,
  className,
  size = "md",
  style,
}) => {
  let width: number;
  switch (size) {
    case "es":
      width = 400;
      break;
    case "sm":
      width = 800;
      break;
    case "md":
      width = 1250;
      break;
    case "lg":
    default:
      width = 1500;
  }
  return (
    <StyledContainer className={className} width={width} style={{ ...style }}>
      {children}
    </StyledContainer>
  );
};

interface StyledContainerProps {
  width: number;
}

const StyledContainer = styled.div<StyledContainerProps>`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: ${(props) => props.width}px;
  padding: 0 ${(props) => props.theme.spacing[3]}px;
  width: 90%;
  ${(props) => props.theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 0 ${props.theme.spacing[1]}px;
  `}
  ${(props) => props.theme.mediaWidth.upToExtraSmall`
    padding: 20px 15px 10px;
  `}
  ${(props) => props.theme.mediaWidth.upToXXS`
    padding: 20px 5px 10px;
  `}
`;

export default Container;
