import { auction_api_base } from "../constants";
import { request } from "./util";
import { SortOrder } from "../config";
import type { ListParams, NFTInfo } from "./typings";

/** --------------- 交易市场相关 ----------------- */
interface AuctionBase {
  id: number;
  seller: string;
  startingPrice: string;
  endingPrice: string;
  duration: number;
  startAt: number;
  referencePrice: string;
  priceEnd: boolean;
  buyer: null | string;
  price: null | string;
  bidTime: null | number;
  nfts: NFTInfo[];
  bestGrade: number;
}

interface SuccessAuction {
  buyer: string;
  price: string;
  bidTime: number;
}

export interface AuctionDetailSingle extends AuctionBase {
  tokenId: number;
  tokenIds: null;
}

export interface AuctionDetailPack extends AuctionBase {
  tokenIds: string;
  tokenId: null;
}

export type AuctionDetail = AuctionDetailSingle | AuctionDetailPack;

export type AuctionSuccessDetail = AuctionDetail & SuccessAuction;

export interface AuctionsData {
  total_count: number;
  list: AuctionDetail[];
}

/**
 * 交易市场列表
 */
export async function getAllAuction(
  { pageNum, pageSize = 12, grade, sort }: ListParams,
  options?: RequestInit
) {
  const params = new URLSearchParams({
    pageSize: pageSize.toString(),
    pageNum: pageNum.toString(),
  });
  // console.log("");

  if (sort) {
    const { key, order } = sort;
    params.set("sortBy", key);
    params.set("sortType", order);
  }
  if (grade) {
    params.set("grade", grade.toString());
  }
  return request<AuctionsData>(
    `${auction_api_base}/list?${params.toString()}`,
    options
  );
}

interface MyListParams {
  address: string;
  sortBy?: string;
  sortType?: SortOrder;
}
export async function getMyAuction({
  address,
  sortBy = "startedAt",
  sortType = SortOrder.DESC,
}: MyListParams) {
  return request<AuctionsData>(
    `${auction_api_base}/myAuctions?address=${address}&sortBy=${sortBy}&sortType=${sortType}`
  );
}

/**
 * 通过 id 查找拍卖
 */
export async function getAuctionById(id: number) {
  return request<AuctionDetail>(`${auction_api_base}/${id}`);
}

export interface AuctionDashboard {
  nftSum: number;
  priceSum: number;
}
export async function getAuctionDashboard(last?: number) {
  return request<AuctionDashboard>(
    `${auction_api_base}/dashboard?last=${last}`
  );
}

export async function getRecentBids() {
  return request<AuctionSuccessDetail[]>(`${auction_api_base}/recent`);
}

export interface BidsData {
  total_count: number;
  list: AuctionSuccessDetail[];
}

export async function getMyBids(address: string) {
  return request<BidsData>(`${auction_api_base}/myBids?address=${address}`);
}
