const body = document.body;

export function change() {
  const width = window.innerWidth;
  if (width <= 1720 && width > 800) {
    // width - 5000
    const rate = 2 - 3720 / (width + 2000);
    window.requestAnimationFrame(function () {
      body.style.zoom = rate.toFixed(3);
    });
  } else if (body.style.zoom !== "") {
    body.style.zoom = "";
  }
}

export function removeZoom() {
  if (body.style.zoom !== "") {
    body.style.zoom = "";
  }
}
