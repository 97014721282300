import { useContext } from "react";
import { Context } from "src/contexts/Farms";
import type { StakedValue } from "src/contexts/Farms/types";

const useStakedValueByPid = (pid: number): [StakedValue | undefined, () => void] => {
  const farmContext = useContext(Context);
  const info = farmContext.allStakedValue.find((ele) => ele.pid === pid);
  const refresh = farmContext.freshAllStakedValue;
  return [info, refresh];
};

export default useStakedValueByPid;
