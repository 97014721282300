// import { useCallback } from "react";
import styled from "styled-components";
import { useLocation, NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { StyledNav } from "src/components/StyledComponents";
import SubNav from "../components/SubNav";
import { chainId } from "src/config";

import button_2 from "src/images/button_2.png";
import button_2_s from "src/images/button_2_s.png";
import button_4 from "src/images/button_4.png";
import button_4_s from "src/images/button_4_s.png";

import home from "src/images/sidebar/menu_home.png";
import farm from "src/images/sidebar/menu_farm.png";
import blindbox from "src/images/sidebar/menu_blindbox.png";
import myNft from "src/images/sidebar/menu_my.png";
import market from "src/images/sidebar/menu_market.png";
import game from "src/images/sidebar/menu_game.png";
import ranking from "src/images/sidebar/menu_rankings.png";

// import homeIcon from "src/images/sidebar/home.png";
// import farmIcon from "src/images/sidebar/farm.png";
// import blindboxIcon from "src/images/sidebar/blindbox.png";
// import myIcon from "src/images/sidebar/my.png";
// import marketIcon from "src/images/sidebar/market.png";
// import rankingIcon from "src/images/sidebar/ranking.png";

import Menu, { SubMenu, MenuItem } from "rc-menu";
import "./styles.css";
import type { CSSMotionProps, MotionEventHandler } from "rc-motion";

const collapseNode = () => {
  return { height: 0 };
};
const expandNode: MotionEventHandler = (node) => {
  return { height: node.scrollHeight };
};

const defaultMotion: CSSMotionProps = {
  motionName: "rc-menu-collapse",
  motionAppear: true,
  onAppearStart: collapseNode,
  onAppearActive: expandNode,
  onEnterStart: collapseNode,
  onEnterActive: expandNode,
  onLeaveStart: expandNode,
  onLeaveActive: collapseNode,
};

export default function Nav() {
  const location = useLocation();
  const { t } = useTranslation();
  const pathname = location.pathname;
  // console.log("pathname", pathname);
  // const isFarmsRoute = pathname.startsWith("/tinyvault");
  // const isBlindRoute = pathname.startsWith("/mysterybox");
  // const isMarketRoute = pathname.startsWith("/marketplace");
  // const isMyNFTRoute = pathname.startsWith("/mynfts");

  const [_, openKey] = pathname.split("/");
  const history = useHistory();

  const linkTo = (key: string) => {
    history.push(key);
  };

  return (
    <StyledContainer>
      <Menu
        mode="inline"
        onClick={(info) => linkTo(info.key)}
        defaultMotions={{ inline: defaultMotion }}
        defaultSelectedKeys={[pathname]}
        defaultOpenKeys={["/" + openKey]}
        // onClick={(info) => {
        //   console.log("info", info);
        // }}
      >
        <MenuItem key="/home">
          <NavLink to="/home">
            <MenuIconImg src={home} />
            <span>{t("Home")}</span>
          </NavLink>
        </MenuItem>

        <SubMenu
          title={
            <span className="submenu-title-wrapper">
              <MenuIconImg src={farm} />
              <span>{t("Mine")}</span>
            </span>
          }
          key="/tinyvault"
        >
          {chainId === 56 ? null : (
            <MenuItem key="/tinyvault/stake">
              <NavLink to="/tinyvault/stake">
                <StyledCircleIcon />
                {t("stakeBattleground")}
              </NavLink>
            </MenuItem>
          )}
          <MenuItem key="/tinyvault/LP">
            <NavLink to="/tinyvault/LP">
              <StyledCircleIcon />
              {t("LPBattleground")}
            </NavLink>
          </MenuItem>
          <MenuItem key="/tinyvault/nft-farming">
            <NavLink to="/tinyvault/nft-farming">
              <StyledCircleIcon />
              {t("Battleground")}
            </NavLink>
          </MenuItem>
        </SubMenu>
        <SubMenu
          title={
            <span className="submenu-title-wrapper">
              <MenuIconImg src={blindbox} />
              {t("Mint NFT")}
            </span>
          }
          key="/mysterybox"
        >
          <MenuItem itemIcon={""} key="/mysterybox/daily">
            <NavLink to="/mysterybox/daily">
              <StyledCircleIcon />
              {t("summon rune")}
            </NavLink>
          </MenuItem>
          {chainId === 97 && (
            <MenuItem itemIcon={""} key="/mysterybox/presale">
              <NavLink to="/mysterybox/presale">
                <StyledCircleIcon />
                {t("Presale")}
              </NavLink>
            </MenuItem>
          )}
        </SubMenu>

        <SubMenu
          title={
            <span className="submenu-title-wrapper">
              <MenuIconImg src={myNft} />
              {t("myNFTs")}
            </span>
          }
          key="/mynfts"
        >
          <MenuItem key="/mynfts/">
            <NavLink
              isActive={(_, location) =>
                !location.pathname.includes("collections")
              }
              to="/mynfts/"
              strict
            >
              <StyledCircleIcon />
              {t("myNFTs")}
            </NavLink>
          </MenuItem>
          <MenuItem key="/mynfts/collections">
            <NavLink to="/mynfts/collections">
              <StyledCircleIcon />
              {t("Collections")}
            </NavLink>
          </MenuItem>
        </SubMenu>

        <SubMenu
          title={
            <span className="submenu-title-wrapper">
              <MenuIconImg src={market} />
              {t("market")}
            </span>
          }
          key="/marketplace"
        >
          <MenuItem key="/marketplace/">
            <NavLink
              isActive={(_, location) =>
                !location.pathname.includes("dashboard")
              }
              to="/marketplace/"
              strict
            >
              <StyledCircleIcon />
              {t("marketplace")}
            </NavLink>
          </MenuItem>
          <MenuItem key="/marketplace/dashboard">
            <NavLink to="/marketplace/dashboard">
              <StyledCircleIcon />
              {t("dashboard")}
            </NavLink>
          </MenuItem>
        </SubMenu>

        <MenuItem key="/rankings">
          <NavLink to="/rankings">
            <MenuIconImg src={ranking} />
            {t("rankings")}
          </NavLink>
        </MenuItem>

        <MenuItem key="/game">
          <NavLink to="/game">
            <span>
              <MenuIconImg src={game} />
              {t("Tiny Kingdom")}
            </span>
          </NavLink>
        </MenuItem>
      </Menu>
    </StyledContainer>
  );
}

function MenuIconImg({ src }: { src: string }) {
  return (
    <span className="position-relative menu-icon-img-box mr-5">
      <img className="menu-icon-img" src={src} width="27" height="23" alt="" />
    </span>
  );
}

const StyledContainer = styled.div`
  position: absolute;
  top: 11.1rem;
  bottom: 140px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 1.3rem;
  &::-webkit-scrollbar {
    display: block;
    width: 7px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e69c10;
    border-radius: 10px;
  }
`;

const StyledCircleIcon = styled.div.attrs((_) => ({
  className: "menu-cirlce",
}))`
  display: inline-block;
  margin-right: 30px;
  width: 5px;
  height: 5px;
  border: 2px #905419 solid;
  border-radius: 50%;
`;

const StyledButton = styled(StyledNav)`
  display: inline-flex;
  align-items: center;
  margin-top: 0.2rem;
  padding-left: 4px;
  padding-right: 0.9rem;
  padding-bottom: 0.4rem;
  width: 11.7rem;
  height: 3.5rem;
  line-height: 3.2rem;
  font-size: 1.35rem;
  color: ${(props) => props.theme.color.white};
  /* background-image: url(${button_4}); */
  background-position: 0% 50%;
  transform-origin: left;
  /* font-family: AvenirNextLTPro;

  &:hover,
  .active > & {
    /* background-image: url(${button_4_s}); */
  /* transform: scale(1.1); */
  .active > & {
    text-shadow: 0px 4px 0px #00000055;
    > img {
      width: 31px;
      filter: drop-shadow(0 4px 0 #33333388);
    }
  }
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    margin-top: 0.3rem;
    width: 11.5rem;
    height: 3.2rem;
    font-size: 1.3rem;
  `};
`;

const StyledGameButton = styled(StyledButton)`
  background-image: url(${button_2});
  &:hover,
  .active > & {
    background-image: url(${button_2_s});
  }
`;

const StyledIconImg = styled.img`
  position: absolute;
  top: 2px;
  margin-right: 5px;
  vertical-align: middle;
  filter: drop-shadow();
`;

const SubButton = styled(StyledNav)`
  position: relative;
  display: block;
  margin: 4px 2px;
  padding-left: 0.2rem;
  padding-right: 1.2rem;
  height: 2.2rem;
  line-height: 2.2rem;
  font-size: 1.07rem;
  background-color: #295994;
  white-space: nowrap;
  clip-path: polygon(0% 0, 100% 0%, 85% 100%, 0 100%);
  .active > & {
    background-color: #5194e5;
    transform: scale(1.1);
    transform-origin: left;
  }
`;
