import Web3 from "web3";
import { ethers } from "ethers";
import config from "../config";
import type { HttpProvider } from "web3-core"
import type { ExternalProvider } from "@ethersproject/providers"

let web3: HttpProvider | undefined = undefined;

function web3ProviderFrom(endpoint: string) {
  return new Web3.providers.HttpProvider(endpoint, {
    timeout: 12000,
  });
}

export function getWeb3Provider() {
  return web3;
}

export function getDefaultProvider(): ethers.providers.Web3Provider {
  const provider = web3ProviderFrom(config.defaultProvider) as unknown;
  web3 = provider as HttpProvider;
  return new ethers.providers.Web3Provider(provider as ExternalProvider, config.chainId);
  // if (typeof config.defaultProvider === "string") {
  //   }
  // web3 = new Web3(config.defaultProvider);
  // return new ethers.providers.Web3Provider(config.defaultProvider, config.chainId);
}
