import styled from "styled-components";
import { NavLink } from "react-router-dom";
import audit from "src/images/audit.png";

import di2 from "src/images/di_2.png";
import home from "src/images/home.png";
import rankings from "src/images/drawer/rankings.png"
import docs from  "src/images/icon_gb.png";
import language from "src/images/icon_lang.png";
import stats from "src/images/stats.png";
import twPng from "src/images/icon_tw.png";
import tgPng from "src/images/icon_tg.png";
import dcPng from "src/images/icon_dc.png";
import mdPng from "src/images/icon_md.png";
import airdrop from "src/images/airdrop.png";

import { useState, useEffect } from "react";
import AirdropModal from "src/views/PageComponents/Topbar/AirdropModal";
import { useTranslation } from "react-i18next";
import useModal from "src/hooks/useModal";

const configs: [string, string][] = [
    ["zh", "繁體中文"],
    ["en", "English"],
    ["ru", "русский язык"],
  ];
  const configMap = new Map(configs);

function getLang(lang: string) {
    for (const iterator of configMap.entries()) {
      const [key, value] = iterator;
      if (lang.includes(key)) {
        return value;
      }
    }
    return "English";
}

export default function Drawer() {
    const [showDrawer, setShowDrawer] = useState(false)
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const [expand, setExpand] = useState(false)

    const handleMoreClick = () => {
        setShowDrawer(true);
    }

    const handleLangChange = (symbol: string) => {
        i18n.changeLanguage(symbol);
        setExpand(false);
    }
 
    // const [showAirdropModal] = useModal(<AirdropModal />);

    return (
        <StyledContainer>
            {!showDrawer && <StyledMore onClick={handleMoreClick}>
                <StyledMoreContent>
                    <StyledDot />
                    <StyledDot />
                    <StyledDot />
                </StyledMoreContent>
            </StyledMore>}
            <StyledMask show={showDrawer} onClick={() => setShowDrawer(false)} />
        
        <StyledAsideContainer show={showDrawer}>
            {
                showDrawer && (
                    <StyledMenuContainer>
                <StyledMenuItem>
                    <a target="_blank" href="https://www.certik.com/projects/tinyworld">
                        <StyledAuditImg src={audit} alt="auditby" />        
                    </a>
                </StyledMenuItem>
                <StyledMenuItem>
                    <NavLink to="/home" className="menu">
                        <StyledIconContainer>
                            <img src={home} alt="home" />
                        </StyledIconContainer>
                        Home
                    </NavLink>
                </StyledMenuItem>
                <StyledMenuItem>
                    <NavLink to="/rankings" className="menu">
                        <StyledIconContainer>
                            <img style={{marginLeft: -4}} height={23} src={rankings} alt="rankings" />
                        </StyledIconContainer>
                        Rankings
                    </NavLink>
                </StyledMenuItem>
                <StyledMenuItem>
                    <a href={lang === "zh" ? "https://docs.tinyworlds.io/v/xiao-xiao-shi-jie-1/" : "https://docs.tinyworlds.io/"} target="_blank" className="menu">
                        <StyledIconContainer>
                            <img src={docs} style={{ marginLeft: -2 }} alt="docs" />
                        </StyledIconContainer>
                        Docs
                    </a>
                </StyledMenuItem>
                <StyledMenuItem>
                    <NavLink to="/home" className="menu">
                        <StyledIconContainer>
                            <img src={stats} alt="governance" />
                        </StyledIconContainer>
                        Governance
                    </NavLink>
                </StyledMenuItem>
                <StyledMenuItem className="position-relative">
                        <StyledLangContainer onClick={() => setExpand(!expand)}>
                            <StyledIconContainer>
                                <img src={language} alt="language" />
                            </StyledIconContainer>
                            {lang.toUpperCase()}
                            <StyledWTriangle style={expand ? {transform: "rotate(180deg)"} : {}}/>
                        </StyledLangContainer>
                        {
                            expand && (
                                <StyledSubMenu>
                                    {configs.map((ele) => {
                                        const [symbol, text] = ele;
                                        if (symbol !== lang) {
                                            return (
                                                <StyledSubMenuItem key={symbol} onClick={() => handleLangChange(symbol)}>
                                                    {text}
                                                </StyledSubMenuItem>
                                            )
                                        }
                                    })}
                                </StyledSubMenu>
                            )
                        }
                </StyledMenuItem>

                <div className="position-relative" style={{height: "100%", zIndex: 2}}>
                    <StyledContent>
                        {/* <StyledDrop onClick={showAirdropModal}>
                            <img height="16" src={airdrop} alt="" />
                            <span className="ml-5">{t("getAirdrop")}</span>
                        </StyledDrop> */}
                        <StyledMediaContainer>
                            <a target="_blank" href="https://twitter.com/tinyworldgamefi"><StyledImg src={twPng} alt="Twitter" /></a>
                            <a target="_blank" href="https://discord.gg/jXTRTz7B7N"><StyledImg src={dcPng} alt="Discord" /></a>
                            <a target="_blank" href={lang === "zh" ? "https://t.me/tinyworld_cn" : "https://t.me/tinyworld_en"}><StyledImg src={tgPng} alt="Telegram" /></a>
                            <a target="_blank" href="https://medium.com/@tinyworld.gamefi"><StyledImg src={mdPng} alt="Medium" /></a>
                        </StyledMediaContainer>
                    </StyledContent>
                </div>
            </StyledMenuContainer>
                )
            }
            
        </StyledAsideContainer>
    </StyledContainer>
    )
} 

const StyledContainer = styled.div`
    display: none;
    position: absolute;
    top: 6rem;
    height: calc(100% - 10rem);
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        display: flex;
        z-index: 19;
    `};
`

const StyledAuditImg = styled.img`
    height: 29px;
    margin: 50px auto 20px;
    max-width: 120px;
`

const StyledMask = styled.div<{show: boolean}>`
    position: absolute;
    width: ${({ show }) => show ? "100vw" : 0};
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    transition: opacity 0.4s ease 0s;
    opacity: ${({ show }) => show ? 0.6 : 0};
`

const StyledAsideContainer = styled.div<{show: boolean}>`
    position: absolute;
    right: -100vw;
    width: ${({show}) => show ? "41vw" : 0 };
    height: 100%;
    background-image: url(${di2});
    transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
`

const StyledMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 20px;
    padding-left: 1rem;
`

const StyledMenuItem = styled.button`
    .menu {
        display: flex;
        align-items: center;
        gap: 5px;
        height: 25px;
        color: #514B45;
        text-decoration: none;
    }
`

const StyledIconContainer = styled.div`
    width: 25px;
    display: flex;
    align-items: center;
`

const StyledImg = styled.img`
    height: 35px;
`

const StyledMore = styled.button`
    z-index: 100; /* z-index 应该大于下边框position-relative */
    position: fixed;
    right: 0;
    bottom: 25%;
    height: 6rem;
    width: 2rem;
    display: none;
    align-items: center;
    justify-content: center;
    background: #275287;
    border-radius: 20px 0 0 20px;
    box-shadow: 0px 3px 3px rgb(0 0 0 / 21%);
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        display: flex;
    `};
`

const StyledMoreContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

const StyledDot = styled.div`
    background: white;
    height: 5px;
    width: 5px;
    border-radius: 5px;
`

const StyledMediaContainer = styled.div`
    right: -1rem;
    border-top: 1px solid #514B45;
    display: grid;
    grid: repeat(2, 45px) / auto-flow 45px;
    width: 100%;
    padding: 2rem 0 5rem 1rem;
`

export const StyledTriangle = styled.div`
    transform: rotate(90deg);
    transition: transform .24s;
    ::after {
        content: "►";
    }
`

const StyledWTriangle = styled.div`
  border-color: transparent transparent #514b45 transparent;
  border-style: solid;
  border-width: 0px 4px 4px 4px;
  height: 0px;
  width: 0px;
`

const StyledLangContainer = styled.div`
    width: 130px;
    display: flex;
    align-items: center;
    gap: 5px;
    height: 25px;
    color: #514B45;
`

const StyledSubMenu = styled.div`
    z-index: 10;
    position: absolute;
    left: 40px;
    top: 35px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 15px;
    color: #514B45;
`

const StyledSubMenuItem = styled.div`

`

const StyledContent = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
`

const StyledDrop = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 7.3rem;
  height: 2.6rem;
  margin-bottom: 14px;
  background-color: #5194e5;
  border-radius: 33px;
  text-align: center;
  font-size: 1.1rem;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.21);
`