import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BigNumber, utils } from "ethers";

import Button from "../Button";
import Modal, { ModalProps } from "../Modal";
import ModalActions from "../ModalActions";
import ModalTitle from "../ModalTitle";
import TokenInput from "../TokenInput";

import {
  getDisplayBalance,
  getFullDisplayBalance,
} from "../../utils/formatBalance";
import ParallelogramButton from "../Button/ParallelogramButton";
import { FeesTable } from "../DepositModal/DepositModal";

interface WithdrawModalProps extends ModalProps {
  max: BigNumber;
  onConfirm: (amount: string) => void;
  tokenName?: string;
  decimals?: number;
  fractionDigits?: number;
  showFeesTable?: boolean;
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({
  onConfirm,
  onDismiss,
  max,
  tokenName = "",
  decimals = 18,
  fractionDigits = 4,
  showFeesTable=false,
}) => {
  console.log("WithdrawModal max", max);
  const [val, setVal] = useState("");
  const { t } = useTranslation();

  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(max, decimals);
  }, [max, decimals]);

  const displayBalance = useMemo(() => {
    return getDisplayBalance(max, decimals, fractionDigits);
  }, [max, decimals, fractionDigits]);

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      if (value.startsWith("00")) return setVal(value.slice(1));
      if (value && value.includes(".")) {
        const [integerPart, decimalPart] = value.split(".");
        if (decimalPart.length > decimals) {
          return setVal(integerPart + "." + decimalPart.slice(0, decimals));
        }
      }
      return setVal(value);
    },
    [setVal, decimals]
  );

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance.toString());
  }, [fullBalance, setVal]);

  const priceBN = utils.parseUnits(val || "0", decimals);
  // console.log('priceBN', priceBN);
  // console.log('max', max);

  const disabledConfirm = val === "" || val === "0" || priceBN.gt(max);
  // const canConfirm = typeof inputMin === "undefined" || val > inputMin;

  return (
    <Modal style={{maxWidth: 430}}>
      <ModalTitle text={`${t("Withdraw")} ${tokenName}`} />
      <TokenInput
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        value={val}
        max={displayBalance}
        symbol={tokenName}
      />
      {showFeesTable && (
        <div className="mt-10">
          <FeesTable />
        </div>
      )}
      <ModalActions>
        <ParallelogramButton onClick={onDismiss}>{t("Cancel")}</ParallelogramButton>
        <ParallelogramButton
          disabled={disabledConfirm}
          onClick={() => onConfirm(val)}
        >
          {t("Confirm")}
        </ParallelogramButton>
      </ModalActions>
    </Modal>
  );
};

export default WithdrawModal;
