import { BigNumber } from "ethers";

export const getDisplayBalance = (
  balance: BigNumber,
  decimals = 18,
  fractionDigits = 3
) => {
  const number = getBalance(balance, decimals - fractionDigits);
  return (number / 10 ** fractionDigits).toFixed(fractionDigits);
};

export const getFullDisplayBalance = (
  balance: BigNumber,
  decimals = 18,
  fractionDigits = 6
) => {
  return Number(
    getDisplayBalance(
      balance,
      decimals,
      fractionDigits > decimals ? decimals : fractionDigits
    )
  );
};

export function getBalance(balance: BigNumber, decimals = 18): number {
  return balance.div(BigNumber.from(10).pow(decimals)).toNumber();
}
