import { memo } from "react";
import styled from "styled-components";
import Language from "./components/Language";

import twPng from "src/images/icon_tw.png";
import tgPng from "src/images/icon_tg.png";
import dcPng from "src/images/icon_dc.png";
import mdPng from "src/images/icon_md.png";
import gbPng from "src/images/icon_gb.png";
import { useTranslation } from "react-i18next";

function Bottom() {
  const { i18n } = useTranslation()
  const lang = i18n.language;

  return (
    <StyledBottom className="display-flex flex-column">
      <Language />
      <StyledLine />
      <StyledIconList>
        <a target="_blank" href="https://twitter.com/tinyworldgamefi"><img src={twPng} alt="Twitter" /></a>
        <a target="_blank" href={lang === "zh" ? "https://t.me/tinyworld_cn" : "https://t.me/tinyworld_en"}><img src={tgPng} alt="Telegram" /></a>
        <a target="_blank" href="https://discord.gg/jXTRTz7B7N"><img src={dcPng} alt="Discord" /></a>
        <a target="_blank" href="https://medium.com/@tinyworld.gamefi"><img src={mdPng} alt="Medium" /></a>
        <a target="_blank" href={lang === "zh" ? "https://docs.tinyworlds.io/v/xiao-xiao-shi-jie-1/" : "https://docs.tinyworlds.io/"}><img src={gbPng} alt="Gitbook" /></a>
      </StyledIconList>
    </StyledBottom>
  );
}

export default memo(Bottom);

const StyledBottom = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  justify-content: flex-end;
  flex-shrink: 0;
  height: 120px;
  width: 97%;
`;

const StyledLine = styled.div`
  margin: 1rem 0;
  border-bottom: 1px solid #707070;
`;

const StyledIconList = styled.div`
  margin: 0 15px 15px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;
