import BigNumberJS from "bignumber.js";
import { Contract, BigNumber, constants, utils } from "ethers";
import { staminaCoefficient } from "../config";
import contractInfoMap from "src/tiny/contractInfoMap";
import type { JsonRpcSigner, Web3Provider } from "@ethersproject/providers";
import type { HeroDesc, PageResult } from "../services/typings";

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, startChars = 4, endChars = 4): string {
  const parsed = utils.isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${address.substring(0, startChars + 2)}...${address.substring(
    42 - endChars
  )}`;
}

// account is not optional
export function getSigner(
  library: Web3Provider,
  account: string
): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract {
  if (!utils.isAddress(address) || address === constants.AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account) as any
  );
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function formatFontSize(fs: number | string | undefined) {
  if (!fs) return undefined;
  return typeof fs === "string" ? fs : fs + "px";
}

export function noFunc() {}

export const bn0 = BigNumber.from("0");

export const bn1e18 = BigNumber.from(10).pow(18);

export function formatMarketPrice(price: string) {
  return new BigNumberJS(price).div(1e6).toNumber();
  // return BigNumber.from(price).div(1e6).toString();
}

export function getNameFromDesc(lang: string, desc?: HeroDesc) {
  if (!desc) return;
  const nameStr = lang.includes("zh") ? "name_cn" : "name_en";
  return desc[nameStr];
}

export function getPageCount(result?: PageResult) {
  if (result) {
    return Math.ceil(result.total_count / result.page_size);
  }
  return 0;
}

const BLOCKS_PER_DAY = (24 * 1200) / staminaCoefficient;
export function calcStamina(
  ownerAddress: string,
  lastBlock?: number | null,
  stamina?: number | null,
  blockNumber?: number
) {
  // 没有值
  if (
    lastBlock === undefined ||
    lastBlock === null ||
    stamina === undefined ||
    stamina === null
  ) {
    return undefined;
  }
  // 首先判断是不是在矿池中
  if (ownerAddress !== contractInfoMap.TinyNFTFarm.address) {
    // 如果不在矿池中，直接返回现在的体力值
    return stamina / BLOCKS_PER_DAY;
  }
  // console.log('stamina', stamina);
  // 在矿池中
  if (!blockNumber) {
    return undefined;
  }
  const duration = blockNumber - lastBlock;
  // const duration = (blockNumber - lastBlock);
  // console.log("duration", duration);
  return Math.max((stamina - duration) / BLOCKS_PER_DAY, 0);
}

export function trimZero(number: string | number) {
  let a = typeof number === "string" ? number : number.toString();
  while (a.includes(".") && (a.endsWith("0") || a.endsWith("."))) {
    a = a.slice(0, -1);
  }
  return a;
}

export function ordinalSuffixOf(serialNum: number) {
  var j = serialNum % 10,
      k = serialNum % 100;
  if (j == 1 && k != 11) {
      return serialNum + "st";
  }
  if (j == 2 && k != 12) {
      return serialNum + "nd";
  }
  if (j == 3 && k != 13) {
      return serialNum + "rd";
  }
  return serialNum + "th";
}

export function toHHMMSS(secs: number): string {
  var hours   = Math.floor(secs / 3600)
  var minutes = Math.floor(secs / 60) % 60
  var seconds = secs % 60

  return [hours,minutes,seconds]
      .map(v => v < 10 ? "0" + v : v)
      .join(":")
}