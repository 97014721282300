import React, { useCallback, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { ApprovalState } from "src/hooks/useApprove";
import ParallelogramButton from "src/components/Button/ParallelogramButton";

interface Props {
  approveStatus: ApprovalState;
  approve(): void;
  onPresentDeposit(): void;
  onPresentWithdraw(): void;
}
export default function ActionButtons({
  approveStatus,
  approve,
  onPresentDeposit,
  onPresentWithdraw,
}: Props) {
  const btnWidth = "7.2rem";
  const { t } = useTranslation();

  const handleDepositClick = useCallback(() => {
    onPresentDeposit();
  }, [onPresentDeposit]);
  const handleWithdrawClick = useCallback(() => {
    onPresentWithdraw();
  }, [onPresentWithdraw]);
  return (
    <Fragment>
      {ApprovalState.NOT_APPROVED === approveStatus ||
      ApprovalState.PENDING === approveStatus ? (
        <ParallelogramButton
          width={btnWidth}
          onClick={approve}
          disabled={ApprovalState.PENDING === approveStatus}
        >
          {t("Approve")}
        </ParallelogramButton>
      ) : (
        <ParallelogramButton
          width={btnWidth}
          disabled={ApprovalState.UNKNOWN === approveStatus}
          onClick={handleDepositClick}
        >
          {t("stake")}
        </ParallelogramButton>
      )}
      <ParallelogramButton
        className="ml-10"
        width={btnWidth}
        onClick={handleWithdrawClick}
      >
        {t("Withdraw")}
      </ParallelogramButton>
    </Fragment>
  );
}
