import styled from "styled-components";
import type { FC } from "react";

/**
 *
 * @param {string} className
 * @param {number} ml
 * @param {boolean} mpt mobile padding-top
 * @returns
 */
const PageContent: FC<{ className?: string; ml?: number; mpt?: boolean, isZoom?: boolean }> = ({
  children,
  className,
  ml,
  mpt,
  isZoom = true
}) => {
  return (
    <StyledContent className={className} ml={ml} mpt={mpt} isZoom={isZoom}>
      {children}
    </StyledContent>
  );
};

export default PageContent;

const StyledContent = styled.div<{ ml?: number; mpt?: boolean, isZoom?: boolean }>`
  padding: 20px 20px 2rem;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  margin-left: ${(props) => (props.ml ? props.ml + "px" : null)};
  ${({ theme, mpt, isZoom }) => theme.mediaWidth.upToExtraSmall`
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: ${mpt ? "4.8rem" : "0.2rem"};
    padding-bottom: 4.5rem;
    ${isZoom ? "zoom: 0.9;" : null}
  `};
`;
