import { useCallback, useEffect, useState } from "react";
import { useWallet } from "src/use-wallet/index";
import { BigNumber } from "ethers";
import ERC20 from "../tiny/ERC20";

// const APPROVE_AMOUNT = ethers.constants.MaxUint256;

const allowanceCache: {
  [key: string]: BigNumber;
} = {};

const useAllowance = (
  token: ERC20,
  spender: string,
  pendingApproval?: boolean
) => {
  const [allowance, setAllowance] = useState<BigNumber | null>(
    allowanceCache[token.address + spender] || null
  );
  const { account } = useWallet();
  const [preApproval, setPreApproval] = useState(pendingApproval);

  // console.log("allowance", allowance);
  const fetchAllowance = useCallback(async () => {
    const currentAllowance = await token.allowance(account as string, spender);
    console.log(
      `Allowance: ${currentAllowance.toString()} ${token.symbol} for ${spender}`
    );
    allowanceCache[token.address + spender] = currentAllowance;
    setAllowance(currentAllowance);
    return currentAllowance;
  }, [account, spender, token]);

  useEffect(() => {
    if (account && spender && token) {
      fetchAllowance().catch((err) =>
        console.error(`Failed to fetch allowance: ${err.stack}`)
      );
    }
  }, [account, spender, token, fetchAllowance]);

  useEffect(() => {
    // 当 pendingApproval 变成 true ，将 preApproval 设为 true
    if (pendingApproval && !preApproval) {
      setPreApproval(pendingApproval);
    }
  }, [preApproval, pendingApproval]);

  const fetchDelayAllowance = useCallback(
    (cb?: Function) => {
      window.setTimeout(() => {
        fetchAllowance()
          .then((value) => {
            if (value.isZero()) {
              cb?.();
            }
          })
          .catch((err) =>
            console.error(`Failed to fetch allowance: ${err.stack}`)
          );
      }, 3_500);
    },
    [fetchAllowance]
  );

  useEffect(() => {
    if (
      account &&
      spender &&
      token &&
      pendingApproval === false &&
      preApproval
    ) {
      // 如果 pendingApproval 变成 false
      // console.log("pendingApproval", pendingApproval);
      // console.log("preApproval", preApproval);
      fetchDelayAllowance(() => {
        fetchDelayAllowance(() => {
          fetchDelayAllowance(fetchDelayAllowance)
        });
      });
    }
  }, [
    account,
    spender,
    token,
    fetchAllowance,
    pendingApproval,
    preApproval,
    fetchDelayAllowance,
  ]);

  return allowance;
};

export default useAllowance;
