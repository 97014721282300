import { useContext, useMemo } from "react";
import styled from "styled-components";
// import bannerImg from "../../images/home_banner.png";
import banner from "../../images/banner.png";
import bannerMobile from "../../images/banner_s.png";
import brandIcon from "../../images/brand.png";
import { useTranslation } from "react-i18next";

import useAutoFreshRequest from "src/hooks/useAutoFreshRequest";
import { getAuctionDashboard, AuctionDashboard } from "src/services/auctions";
import config from "src/config";

import FarmContext from "src/contexts/Farms/context";

import EconomyInfo from "./EconomyInfo";
import useIsMobile from "src/hooks/useIsMobile";

const chainId = config.chainId;

export default function Banner() {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const [overview] = useAutoFreshRequest<AuctionDashboard>(
    getAuctionDashboard,
    [],
    { interval: config.refreshInterval * 100 }
  );

  const totalVolume = overview
    ? (overview.priceSum / 1e6).toLocaleString()
    : "--";

  const { lpFarmTVL, tvlTotal } = useContext(FarmContext);

  const sum = useMemo(() => {
    if (chainId === 56) {
      if (lpFarmTVL) {
        return lpFarmTVL.toLocaleString();
      }
      return "--";
    }
    if (lpFarmTVL && tvlTotal) {
      return (lpFarmTVL + tvlTotal).toLocaleString().split(".")[0];
    }
    return "--";
  }, [lpFarmTVL, tvlTotal]);

  return (
    <div
      className="special-ff"
      style={isMobile ? { margin: "-30px 0 10px" } : { margin: "-30px 0 50px" }}
    >
      <StyledContainer className="position-relative">
        <StyledBanner src={isMobile ? bannerMobile : banner} alt="" />
        <StyledBannerInfo>
          <StyledBrand>
            <img src={brandIcon} height={isMobile ? 26 : "auto"} />
          </StyledBrand>
          <div style={{ height: "70%" }}>
            <StyledValueContainer style={{ marginBottom: "4%" }}>
              <StyledLabel>{t("Total Value Locked")}</StyledLabel>
              <StyledValue className="ff-black">$ {sum}</StyledValue>
            </StyledValueContainer>
            <StyledValueContainer>
              <StyledLabel>{t("Total NFT Trading Volume")}</StyledLabel>
              <StyledValue className="ff-black">$ {totalVolume}</StyledValue>
            </StyledValueContainer>
          </div>
        </StyledBannerInfo>
      </StyledContainer>
      <EconomyInfo totalVolume={totalVolume} />
    </div>
  );
}

const StyledContainer = styled.div`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding-top: 20px;
  `}
`;

const StyledBanner = styled.img`
  left: 0;
  top: 0;
  width: 100%;
`;

const StyledBannerInfo = styled.div`
  position: absolute;
  left: 16%;
  top: 21%;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-evenly; */
  height: calc(76% - 10px);

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
  `}
`;

const StyledBrand = styled.div`
  margin: 7% 0 4%;
`;

const StyledValueContainer = styled.div`
  width: auto;
  min-width: 15vw;
  height: 45%;
  padding: 3% 20px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  box-sizing: border-box;
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 0.9rem
  `}
`;

const StyledLabel = styled.div`
  color: white;
  font-size: 1.2rem;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 1rem;
  `}
`;

const StyledValue = styled.div`
  font-size: 1.8rem;
  color: #fccd48;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 1.5rem;
  `}
`;
