import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    height?: number;
}

export default function MaxButton(props: Props) {
  const { children, height = 35, ...rest } = props;
  
  return (
    <StyledToggleButton height={height} {...rest} style={{marginBottom: 2, marginRight: -6}}>
      {children}
    </StyledToggleButton>
  );
}

const StyledToggleButton = styled.button<{ height: number}>`
  min-width: 80px;
  height: ${(props) => props.height}px;
  background-color: #FDCE4F;
  color: #514B45;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.21);
  border-radius: 20px;
  line-height: ${(props) => props.height}px;
  font-size: 1.2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;