import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import homeIcon from "src/images/sidebar/home.png";
import farmIcon from "src/images/sidebar/farm.png";
import blindboxIcon from "src/images/sidebar/blindbox.png";
import myIcon from "src/images/sidebar/my.png";
import marketIcon from "src/images/sidebar/market.png";
import gameIcon from "src/images/sidebar/game.png";
import styles from "./styles.module.css";

export default function BottomBar() {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <StyledBg />
      <LinksGroup className="text-center">
        <NavLink
          className={(isActive) =>
            styles["mobile-nav-button"] + (isActive ? " active" : "")
          }
          to="/tinyvault"
        >
          <StyledIconImg src={farmIcon} alt="" />
          {t("Mine")}
        </NavLink>
        <NavLink
          className={(isActive) =>
            styles["mobile-nav-button"] + (isActive ? " active" : "")
          }
          to="/mysterybox"
        >
          <StyledIconImg src={blindboxIcon} alt="" />
          {t("Mint NFT")}{" "}
        </NavLink>
        <NavLink
          className={(isActive) =>
            styles["mobile-nav-button"] + (isActive ? " active" : "")
          }
          to="/mynfts"
        >
          <StyledIconImg src={myIcon} alt="" />
          {t("myNFTs")}
        </NavLink>
        <NavLink
          className={(isActive) =>
            styles["mobile-nav-button"] + (isActive ? " active" : "")
          }
          to="/marketplace"
        >
          <StyledIconImg src={marketIcon} />
          <span> Market</span>
        </NavLink>
        <NavLink className={styles["mobile-nav-button"]} to="/game">
          <StyledIconImg
            src={gameIcon}
            style={{ paddingTop: 2, paddingBottom: 2, height: 24 }}
          />
          <span>{t("Game")}</span>
        </NavLink>
      </LinksGroup>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 7rem;
  display: none;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.82), rgba(0, 0, 0, 0));
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: block;
    z-index: 19;
  `};
`;

const StyledBg = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 4rem;
  background-color: #1e3d64;
`;

const LinksGroup = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding: 0 2px;
  height: 100%;
  position: relative;
  z-index: 2;
`;

const StyledIconImg = styled.img`
  margin-right: 2px;
  height: 28px;
  vertical-align: middle;
`;
