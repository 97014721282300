import { useEffect, useRef } from "react";
import styled from "styled-components";
import { useThrottleCallback } from "@react-hook/throttle";
import TinyKingdomLogo from "src/images/logo_k.png";
import telegramIcon from "src/images/game/telegram.png";
import twitterIcon from "src/images/game/twitter.png";
import mediumIcon from "src/images/game/medium.png";
import gitbookIcon from "src/images/game/gitbook.png";
import discordIcon from "src/images/game/discord.png";
import border from "src/images/game/border.png";
import bg from "src/images/game/bg.png";
import { useTranslation } from "react-i18next";

interface Props {
  gameSrc: string;
}

function calc(_height: number) {
  const height = _height * 0.9;
  const a = height / 40;
  const b = (a * 3) / 4;
  const borderWidth = `${a.toFixed(1)}px ${b.toFixed(1)}px`;
  return {
    width: height / 2,
    height,
    borderWidth,
  };
}

export default function GamePage({ gameSrc }: Props) {
  const { i18n } = useTranslation()
  const lang = i18n.language;
  const containerRef = useRef<HTMLDivElement>(null);

  const calcWidth = useThrottleCallback(() => {
    const div = containerRef.current;
    if (div) {
      window.requestAnimationFrame(function () {
        const { width, height, borderWidth } = calc(window.innerHeight);
        div.style.height = height + "px";
        div.style.width = width + "px";
        div.style.borderWidth = borderWidth;
      });
    }
  }, 2);
  useEffect(() => {
    if (containerRef.current) {
      window.addEventListener("resize", calcWidth);
      calcWidth();
    }
    return () => {
      window.removeEventListener("resize", calcWidth);
    };
  }, [calcWidth]);

  return (
    <StyledContainer>
      <StyledBrandIcon src={TinyKingdomLogo} />
      <StyledIconList>
        <li>
          <a target="_blank" href={lang === "zh" ? "https://t.me/tinyworld_cn" : "https://t.me/tinyworld_en"}>
            <img src={telegramIcon} />
          </a>
        </li>
        <li>
          <a target="_blank" href="https://twitter.com/tinyworldgamefi">
            <img src={twitterIcon} />
          </a>
        </li>
        <li>
          <a target="_blank" href="https://medium.com/@tinyworld.gamefi">
            <img src={mediumIcon} />
          </a>
        </li>
        <li>
          <a target="_blank" href={lang === "zh" ? "https://docs.tinyworlds.io/v/xiao-xiao-shi-jie-1/" : "https://docs.tinyworlds.io/"}>
            <img src={gitbookIcon} />
          </a>
        </li>
        <li>
          <a target="_blank" href="https://discord.gg/jXTRTz7B7N">
            <img src={discordIcon} />
          </a>
        </li>
      </StyledIconList>
      <StyledIframeContainer
        style={{ ...calc(window.innerHeight) }}
        ref={containerRef}
      >
        {/* <div > */}
        <StyledIframe src={gameSrc}></StyledIframe>

        {/* </div> */}
      </StyledIframeContainer>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background-image: url(${bg}),
    linear-gradient(to right bottom, #3a4168, #040505);
  display: flex;
`;

const StyledBrandIcon = styled.img`
  position: fixed;
  top: 30px;
  left: 30px;
`;

const StyledIframe = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
`;

const StyledIconList = styled.ul`
  list-style: none;
  position: fixed;
  right: 30px;
  bottom: 20px;
  > li {
    margin-bottom: 5px;
    cursor: pointer;
  }
`;

const StyledIframeContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  /* height: 86%;
  width: calc(10% + 300px);
  max-width: 460px;
  min-width: 350px; */
  border-style: solid;
  border-image: url(${border}) 21 18;
  align-items: center;
  margin: auto;
  min-width: 25px;
  min-height: 50px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  
  `}

  ${({ theme }) => theme.mediaWidth.upToLarge`
  
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
  
  `} /* background-repeat: no-repeat; */
`;
