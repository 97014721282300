import { useState, useCallback, Fragment, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import useModal from "src/hooks/useModal";
import useTiny from "src/hooks/useTiny";
import useFarmPrice from "src/hooks/useFarmPrice";

import SideBar from "../SideBar";
import AirdropModal from "./AirdropModal";
import StatusButtons from "./components/StatusButtons";
import Notification from "src/components/Notification";

import logoPngSmall from "src/images/logo_s.png";
import airdrop from "src/images/airdrop.png";
import audit from "src/images/audit.png";
import bg1 from "src/images/upgrade/background1.png";
import bg2 from "src/images/upgrade/background2.png";

import type { BigNumber } from "ethers";
import useIsMobile from "src/hooks/useIsMobile";

function Topbar() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const tiny = useTiny();
  const account = tiny.myAccount;
  const airdropContract = tiny.contracts.TinyAirdrop;
  // console.log("chainId", chainId);
  const [price] = useFarmPrice();

  const [airdropTotal, setAirdropTotal] = useState<{
    tincAmount: BigNumber;
    runeValue: BigNumber;
  }>();

  // const getTotal = useCallback(() => {
  //   // console.log('airdropContract', airdropContract);
  //   airdropContract.getAirdrops().then((res: [BigNumber, BigNumber]) => {
  //     console.log("res", res);
  //     const [tincAmount, runeValue] = res;
  //     if (!tincAmount.isZero() || !runeValue.isZero()) {
  //       setAirdropTotal({ tincAmount, runeValue });
  //     }
  //   });
  // }, [airdropContract]);

  // const [showAirdropModal] = useModal(
  //   <AirdropModal total={airdropTotal} fresh={getTotal} />,
  //   [airdropTotal, getTotal]
  // );

  // useEffect(() => {
  //   if (account) {
  //     getTotal();
  //   }
  // }, [account, getTotal]);

  return (
    <Fragment>
      <StyledHeader>
        {!isMobile && (
          <StyledAudit
            target="_blank"
            href="https://www.certik.com/projects/tinyworld"
          >
            <img src={audit} width={168} height={36} />
          </StyledAudit>
        )}
        <StyledLeft className="display-flex align-center pl-10 fw-500">
          <StyledImg className="mr-5" src={logoPngSmall} alt="" />
          <StyledTINCPriceBox>
            <div style={{ whiteSpace: "nowrap" }}>
              <span className="mobile-hidden">BUY</span> TINC
            </div>
            <StyledPrice className="mr-5">
              ${price["TINC"].toFixed(1)}
            </StyledPrice>
          </StyledTINCPriceBox>
        </StyledLeft>
        <StyledRight>
          <StyledPCNews>
            <Notification />
          </StyledPCNews>
          {/* {airdropTotal && (
            <StyledDrop onClick={showAirdropModal}>
              <img height="18" src={airdrop} alt="" />
              <span className="ml-5">{t("getAirdrop")}</span>
            </StyledDrop>
          )} */}
          <StatusButtons />
        </StyledRight>
      </StyledHeader>
      <SideBar />
    </Fragment>
  );
}

export default memo(Topbar);

const StyledPCNews = styled.div`
  display: block;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `}
`;

const StyledHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 15rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 5.3rem;
  z-index: 99;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    justify-content: space-between;
    align-items: normal;
    height: 6rem;
    padding-left: 0;
    background-image: url(${bg2}), url(${bg1}), linear-gradient(#F9BE3A, #F79905);
    background-color: #F79905;
    background-size: 110%, 35%;
    background-repeat: no-repeat;
    background-position: left -30px top -10px,right 0% top 50%;
  `};
`;

const StyledLeft = styled.div`
  display: none;
  /* font-size: 1.3rem; */
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: flex;
  `}
`;

const StyledAudit = styled.a`
  position: fixed;
  left: 16rem;
`;

const StyledImg = styled.img`
  position: relative;
  height: 82%;
  object-fit: contain;
  z-index: 1;
`;

const StyledTINCPriceBox = styled.div`
  margin-left: -30px;
  padding: 2px 12px 2px 25px;
  color: ${(props) => props.theme.color.yellow};
  font-weight: 600;
  background-color: #905419;
  border: 2px solid #fdce4f;
  border-radius: 36px;
  line-height: 15px;
  /* font-size: 1rem; */
`;

const StyledPrice = styled.span`
  color: white;
`;

const StyledListButton = styled.button`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: block;
  `};
`;

const StyledRight = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
`;

const StyledDrop = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  width: 7.8rem;
  height: 2.4rem;
  background-color: #5194e5;
  border-radius: 33px;
  text-align: center;
  font-size: 1.1rem;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.21);
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 3.2rem;
    background-size: 100% 100%;
    > span {
      display: none;
    }
  `};
`;
