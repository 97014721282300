import clsx from "clsx";
import React, { ReactNode } from "react";
import styled from "styled-components";
import SpecialFontText from "../SpecialFontText"

interface ModalTitleProps {
  className?: string;
  text?: ReactNode;
}

const ModalTitle: React.FC<ModalTitleProps> = ({ text, className }) => (
  <StyledModalTitle className={clsx(className, "special-ff")}>{text}</StyledModalTitle>
);

const StyledModalTitle = styled(SpecialFontText)`
  margin-bottom: ${(props) => props.theme.spacing[2]}px;
  display: flex;
  justify-content: center;
  height: 3.5rem;
  align-items: center;
  color: #514b45;
  font-size: 1.6rem;
  font-weight: 500;
`;

export default ModalTitle;
