import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Modal, { ModalProps } from "../Modal";
import type { NewData } from "src/services/index";

interface NewsModalProps extends ModalProps {
  data: NewData[] | undefined;
}

export default function NewsModal({ onDismiss, data }: NewsModalProps) {
  const { t } = useTranslation();
  // console.log("data", data);
  const list = useMemo(() => {
    if (!data) return null;
    const results = [];
    for (let index = 0; index < data.length; index++) {
      const { title, contents } = data[index];
      const parentKey = data.length - index;
      results.push(
        <div key={data.length - index}>
          <h4>{title}</h4>
          {contents.map((ele, i) => {
            if (ele === "") {
              return <br key={parentKey + "-" + i} />;
            }
            return <div key={parentKey + "-" + i}>{ele}</div>;
          })}
        </div>
      );
    }
    return results;
  }, [data]);
  return (
    <StyledContainer>
      <Modal
        size="sm"
        title={t("announcement") as string}
        onDismiss={onDismiss}
      >
        <StyledContent className="custom-scrollbar">{list}</StyledContent>
      </Modal>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  margin-left: 15rem;
  ${(props) => props.theme.mediaWidth.upToSmall`
    margin-left: 0;
  `}
`;

const StyledContent = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  padding: 0 1rem;
  margin-bottom: 1rem;
`;
