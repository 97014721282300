import {
  useState,
  useCallback,
  DependencyList,
  useEffect,
  useRef,
} from "react";
import config from "../config";

const defaultInterval = config.refreshInterval; // 10_000ms

interface ExternalInfo {
  error: any;
}

function useAutoFreshRequest<T = any>(
  requestFunc: (...params: DependencyList) => Promise<T>,
  params: DependencyList
): [T | undefined, () => Promise<T | undefined>, boolean, ExternalInfo];
function useAutoFreshRequest<T = any>(
  requestFunc: (...params: DependencyList) => Promise<T>,
  params: DependencyList,
  options: {
    interval?: number | boolean;
    initState: T;
    immediate?: boolean;
  }
): [T, () => Promise<T | undefined>, boolean, ExternalInfo];
function useAutoFreshRequest<T = any>(
  requestFunc: (...params: DependencyList) => Promise<T>,
  params: DependencyList,
  options: {
    interval?: number | boolean;
    immediate?: boolean;
  }
): [T | undefined, () => Promise<T | undefined>, boolean, ExternalInfo];
function useAutoFreshRequest(
  requestFunc: () => void,
  params: DependencyList,
  options: {
    interval?: number | boolean;
    immediate?: boolean;
  }
): [undefined, () => Promise<void>, boolean, ExternalInfo];

/**
 * 自动轮询某个请求
 * @param requestFunc
 * @param params
 * @param options
 * @returns
 */
function useAutoFreshRequest<T = any>(
  requestFunc: (...params: DependencyList) => Promise<T> | void,
  params: DependencyList,
  options?: {
    interval?: number | boolean;
    initState?: T;
    immediate?: boolean;
  }
) {
  const { interval = true, initState, immediate = true } = options || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  // console.log("initState", initState);

  const [res, setRes] = useState(initState);
  // const controllerRef = useRef(new AbortController())

  // const timer = useRef<number>();
  const endRef = useRef(false);
  const immediateRef = useRef(immediate);

  const refresh = useCallback(async () => {
    // console.log("useAutoFreshRequest refresh endRef.current", endRef.current);
    if (endRef.current) return;
    // console.log("requestFunc", requestFunc);
    const promise = requestFunc(...params);
    // console.trace("promise", promise);
    // 只有生命周期没有 end 的时候，才调用 set 方法
    // 如果生命周期结束了，说明组件卸载，无需 set
    if (!endRef.current && promise) {
      setLoading(true);
      try {
        const res = await promise;
        // console.log('res', res);
        if (res !== undefined) {
          setRes(res);
        }
        return res;
      } catch (error) {
        setError(error);
      } finally {
        if (!endRef.current) {
          setLoading(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestFunc, ...params]);

  useEffect(() => {
    // 在异步的回调中更改状态，需要注意加判断
    // 根据 immediate 判断一开始是不是调用一次，只有特别指定为 false，才不调用
    if (immediateRef.current === false) {
      immediateRef.current = true;
      return;
    }
    refresh();
  }, [refresh]);

  useEffect(() => {
    return () => {
      endRef.current = true;
    };
  }, []);

  useEffect(() => {
    let timer: number | undefined = undefined;
    if (interval) {
      const t = typeof interval === "number" ? interval : defaultInterval;
      timer = window.setInterval(() => {
        refresh();
      }, t);
      // console.log("useEffect refresh timer", timer);
    }
    return () => {
      // console.log("useAutoFreshRequest timer", timer);
      if (timer) {
        window.clearInterval(timer);
        timer = undefined;
      }
    };
  }, [refresh, interval]);

  return [res, refresh, loading, { error }];
}

export default useAutoFreshRequest;
