import { useCallback, useContext, Fragment, memo } from "react";
import styled from "styled-components";
import { useWallet } from "src/use-wallet/index";
import { useTranslation } from "react-i18next";
import { BigNumber } from "ethers";

import useTokenBalance from "src/hooks/useTokenBalance";
import { Context } from "src/contexts/TinyProvider";

import { shortenAddress } from "src/utils/index";
import { getDisplayBalance } from "src/utils/formatBalance";
import config from "src/config";

function StatusButtons() {
  const { account, connect, balance } = useWallet();
  // console.log("chainId", chainId);
  // console.log("config.chainId", config.chainId);

  const { t } = useTranslation();
  const { tiny, tincBalance } = useContext(Context)
  const BUSD = tiny.externalTokens.BUSD;
  const [busdBalance] = useTokenBalance(BUSD, tiny.myAccount);

  const isNetworkCorrect =
    window.ethereum && config.chainId === Number(window.ethereum.chainId);

  const handleLogin = useCallback(() => {
    if (!account) {
      connect("injected");
    }
  }, [account, connect]);

  return (
    <Fragment>
      <StyledTINCPrice className="mobile-hidden">
        <img
          className="mr-5"
          width={20}
          src="/images/tokens/BNB.png"
          alt="BNB"
        />
        <span className="color-price mr-10">
          {getDisplayBalance(BigNumber.from(balance))}
        </span>
        <img
          className="mr-5"
          width={20}
          src="/images/tokens/BUSD.png"
          alt="BUSD"
        />
        <span className="color-price">{getDisplayBalance(busdBalance)}</span>
      </StyledTINCPrice>
      <StyledTINCPrice className="tinc-balance">
        <img
          className="mr-5"
          width={20}
          src="/images/tokens/TINC.png"
          alt="TINC"
        />
        <span className="color-price">{getDisplayBalance(tincBalance)}</span>
      </StyledTINCPrice>
      {isNetworkCorrect ? (
        <StyledAccount onClick={handleLogin}>
          {account ? shortenAddress(account) : t("unlock")}
        </StyledAccount>
      ) : (
        <NetworkError>{t("NetworkError")}</NetworkError>
      )}
    </Fragment>
  );
}

export default memo(StatusButtons);

const StyledBorderButton = styled.button`
  box-sizing: border-box;
  padding: 4px 12px 4px 8px;
  height: 2.4rem;
  border-radius: 1.2rem;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.21);
  font-weight: 500;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 4px;
  `}
`;

const StyledTINCPrice = styled(StyledBorderButton)`
  display: inline-flex;
  align-items: center;
  margin-right: 15px;
  background-color: #514b45;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 5px;
    .tinc-balance {
      width: max-content
    }
  `}
`;

const StyledAccount = styled(StyledBorderButton)`
  padding: 0.3rem 0.5rem;
  background-color: #fffaf2;
  border: 2px solid #514b45;
  color: #514b45;
`;

const NetworkError = styled(StyledAccount)`
  color: orangered;
`;
