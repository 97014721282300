import { CSSProperties } from "react";
import Question from "src/images/wenhao.png";
import styled from "styled-components";

interface Props {
  onClick?(): void;
  className?: string;
  size?: "md" | "sm";
  style?: CSSProperties;
}
export default function QuestionIcon(props: Props) {
  const { onClick, size = "md", ...rest } = props;
  let height = 1.87;
  if (size === "sm") {
    height = 1.6;
  }
  return (
    <StyledSpan onClick={onClick} height={height} {...rest}>
      <img src={Question} alt="" height="100%" />
    </StyledSpan>
  );
}

const StyledSpan = styled.span<{ height: number }>`
  cursor: pointer;
  height: ${(props) => props.height}rem;
`;
