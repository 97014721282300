import { useCallback, useEffect, useRef } from "react";
import { useAllTransactions } from "src/state/transactions/hooks";
import type { SerializableTransactionReceipt } from "src/state/transactions/actions";

interface Params {
  interval?: number;
}
export default function useHandleTxConfirm(params?: Params) {
  const { interval = 1500 } = params || {};
  const transactions = useAllTransactions();
  const hashListRef = useRef<
    {
      hash: string;
      timer: number;
      count: number;
    }[]
  >([]);

  const transRef = useRef(transactions);
  transRef.current = transactions;
  // console.log("useHandleTxConfirm transactions", transactions);
  useEffect(() => {
    const hashList = hashListRef.current;
    return () => {
      // 生命周期结束的时候清可能存在的定时器
      while (hashList.length) {
        const ele = hashList.pop();
        if (ele) {
          window.clearInterval(ele.timer);
        }
      }
    };
  }, []);

  return useCallback(
    (txHash: string) => {
      return new Promise<SerializableTransactionReceipt>((resolve, reject) => {
        // 每1.5秒钟扫描一次本地缓存
        let timer = window.setInterval(() => {
          // 这里 transactions 用的是缓存。。。
          const transactions = transRef.current;
          const transactionDetail = transactions[txHash];
          const infoIndex = hashListRef.current.findIndex(
            (ele) => ele.hash === txHash
          );
          const info = hashListRef.current[infoIndex];
          // 如果为 -1，说明哪里出错了
          if (infoIndex === -1) {
            window.clearInterval(timer);
            return reject("can not find info by hash " + txHash);
          }
          if (transactionDetail && transactionDetail.receipt) {
            const receipt = transactionDetail.receipt;
            // 从链上查到结果之后，1s 后再从 promise 返回
            window.setTimeout(() => {
              resolve(receipt);
            }, 1_000);
            // 查询到数据，清楚定时器，从 list 中删除
            window.clearInterval(timer);
            hashListRef.current.splice(infoIndex, 1);
            // hashListRef.current = hashList.filter((ele) => ele.hash !== txHash);
            // console.log("hashListRef.current", hashListRef.current);
          } else {
            // 多次没扫到数据，超过60次算超时，失败
            info.count += 1;
            if (info.count > 60) {
              window.clearInterval(timer);
              return reject("transaction timeout");
            }
          }
        }, interval);
        hashListRef.current.push({
          hash: txHash,
          timer,
          count: 0,
        });
      });
    },
    [interval]
  );
}
