const { detect } = require("detect-browser");
const browser = detect();

const specialNames = ["safari", "ios", "ios-webview"];
// handle the case where we don't detect the browser
// console.log(browser.name);
// console.log(browser.version);
// console.log(browser.os);
const style = document.createElement("style");
if (browser && specialNames.includes(browser.name)) {
  style.innerText = `
  .text-outline-07 {
    -webkit-text-stroke: 0.4px black;
    font-weight: 800;
  }
  .text-outline {
    -webkit-text-stroke: 0.5px black;
    text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  }`;
} else {
  style.innerText = `
    .text-outline-07 {
      text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
      font-weight: 800;
    }
    .text-outline {
      text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
    }
  `;
}
document.head.appendChild(style);
