import React, { HTMLAttributes, PropsWithChildren } from "react";
import styled from "styled-components";
import di_2 from "src/images/di_2.png";
import di_3 from "src/images/di_3.png";

interface Props extends HTMLAttributes<HTMLDivElement> {
  bgType?: 1 | 2;
}

const Card: React.FC<PropsWithChildren<Props>> = ({
  children,
  bgType = 1,
  ...rest
}) => (
  <StyledCard {...rest} bgType={bgType}>
    {children}
    <div></div>
  </StyledCard>
);

const StyledCard = styled.div<{ bgType: 1 | 2 }>`
  position: relative;
  border-radius: 12px;
  box-shadow: 24px 24px 48px -24px ${(props) => props.theme.color.grey[900]};
  background-image: ${(props) =>
    props.bgType === 1
      ? `url(${di_2})`
      : `url(${di_3}), linear-gradient(#fcc856, #f9be3a, #f79905)`};
`;

export default Card;
