import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components";

import Modal, { ModalProps } from "src/components/Modal";
import ModalActions from "../ModalActions";
import ParallelogramButton from "../Button/ParallelogramButton";
import { useBlockNumber } from "src/state/application/hooks";

const BetaNoticeModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { t } = useTranslation();
  const blockNumber = useBlockNumber()

  const BetaContent = (
    <StyledOL>
      <li>
        <Trans
          i18nKey="NoticeTips1" // optional -> fallbacks to defaults if not provided
          components={{ red: <span className="notice-text-color" /> }}
        />
      </li>
      <li>
      <Trans
          i18nKey="NoticeTips2"
          components={{ red: <span className="notice-text-color" /> }}
        />
      </li>
      <li>
        <Trans
          i18nKey="NoticeTips3"
          components={{ red: <span className="notice-text-color" /> }}
        />
      </li>
      <li>
        <Trans
          i18nKey="NoticeTips4"
          components={{ red: <span className="notice-text-color" /> }}
        />
      </li>
      <li>{t("NoticeTips5")}</li>
    </StyledOL>
  )
  

  const BetaOverContent = (
    <div>
      <div>{t("betaOverTips1")}</div>
      <br />
      <div>{t("betaOverTips2")}</div>
      <br />
      <div>{t("betaOverTips3")}</div>
      <br />
      <div>{t("betaOverTips4")}</div>
      <br />
      <div className="text-right">{t("betaOverTips5")}</div>
      <br />
    </div>
  )

  return (
    <StyledContainer>
      <Modal
        size="sm"
        title={<span className="notice-text-color">{t("beta over notice")}</span>}
        onDismiss={onDismiss}
        style={{ width: 630 }}
      >
        {
          blockNumber && (blockNumber < 15530449) ? (
            BetaContent
            ) : (
            BetaOverContent
          )
        }
        <ModalActions>
          <ParallelogramButton onClick={() => onDismiss && onDismiss()}>
            {t("Close")}
          </ParallelogramButton>
        </ModalActions>
      </Modal>
    </StyledContainer>
  );
};

export default BetaNoticeModal;

const StyledContainer = styled.div`
  max-width: 520px;
`;

const StyledOL = styled.ol`
  margin-top: 0.5rem;
  padding-left: 1.2rem;
  margin-bottom: -20px;
  > li {
    margin-bottom: 0.5rem;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0 1.5rem;
  `}
`;
