import { my_api_base, farm_api_base } from "../constants";
import { request } from "./util";
import type { nftType } from "src/config";
import type { NFTBaseDetail, NFTInfo, ListParams } from "./typings";

export type { NFTBaseDetail };

/* -------------- 从后端查询 NFT 相关信息 START ---------------- */
interface NFTListRes {
  total_count: number;
  nfts: NFTInfo[];
}

/**
 * 根据 type 查询对应 account 的 nft（即不包含质押的等等）
 * @param address 要查询的地址
 * @param type type 1 是英雄
 */
export function getNFTsByType(
  { address }: { address: string | undefined; type?: nftType },
  options?: RequestInit
) {
  return request<NFTListRes>(
    `${my_api_base}/nfts?address=${address}`,
    options
  );
}

// interface AllNFTsParams extends ListParams {
//   address: string;
// }
// export function getAllNFTsByAccount(
//   params: AllNFTsParams,
//   options?: RequestInit
// ) {
//   const { address, grade, sort, pageNum, pageSize } = params;
//   const a = new URLSearchParams();
//   if (address) a.append("address", address);
//   if (grade) a.append("grade", grade.toString());
//   if (pageNum) a.append("pageNum", pageNum.toString());
//   if (pageSize) a.append("pageSize", pageSize.toString());
//   if (sort) {
//     a.append("sortBy", sort.key);
//     a.append("sortType", sort.order);
//   }
//   return request(`${my_api_base}/allnfts?${a.toString()}`, options);
// }

export function getAllNFTsByAccount(address: string, options?: RequestInit) {
  return request(`${my_api_base}/all?account=${address}`, options);
}

/**
 * 根据 id 查询 nft
 * @param id tokenId
 */
export function getNFTById(id: string | number, options?: RequestInit) {
  return request<NFTInfo>(`${my_api_base}/nft?id=${id}`, options);
}

/**
 * 这是质押挖矿中的 NFT，包含 LP 质押和 小队质押
 * @param address 要查询的地址
 */
export function getStakedNFTs(address: string, options?: RequestInit) {
  return request<NFTListRes>(
    `${my_api_base}/stakednfts?address=${address}`,
    options
  );
}

/**
 * 这是质押挖矿中的 NFT，包含 LP 质押和 小队质押
 * @param address 要查询的地址
 */
export function getGamingNFTs(address: string, options?: RequestInit) {
  return request<NFTListRes>(
    `${my_api_base}/gamingnfts?address=${address}`,
    options
  );
}

export interface LPFarmPowerInfo {
  pool: {
    max_power: number;
    mid_power: number;
    max_bonus: number;
    mid_bonus: number;
  };
}
/**
 * 查询 LP 矿池信息
 */
export function getLPPoolInfo() {
  return request<LPFarmPowerInfo>(`${farm_api_base}/info`);
}

// 各个池子的质押情况
export interface PoolStakeInfo {
  pid: number;
  tvl: number;
  wantLockedTotal: string;
  baseApy: number;
  cakeApr?: {
    cakeRewardsApr: number;
  };
}

export interface StakeInfos {
  [pid: number]: PoolStakeInfo;
}

interface FarmInfos {
  stakeInfos: StakeInfos;
  tvlTotal: number;
  price: {
    [tokenSymbol: string]: number;
  };
}

/**
 * 查询 LP 矿池信息
 */
export function getFarmInfos() {
  return request<FarmInfos>(`${farm_api_base}/pools`);
}

export interface NewData {
  title: string;
  contents: string[];
}

interface NewsRes {
  version: number;
  data: NewData[];
}

const promiseCache: {
  news: Promise<NewsRes> | null;
  whitelist: Promise<string[]> | null;
} = {
  news: null,
  whitelist: null,
};

/**
 * 获取游戏补充白名单
 */
export function getWhitelist() {
  if (promiseCache.whitelist) {
    return promiseCache.whitelist;
  }
  const promise: Promise<string[]> = fetch("/data/whitelist.json").then((res) =>
    res.json()
  );
  promiseCache.whitelist = promise;
  return promise;
}

/**
 * 获取最新新闻
 */
export function getNews() {
  if (promiseCache.news) {
    return promiseCache.news;
  }
  const promise: Promise<NewsRes> = fetch("/data/news.json?version=4").then(
    (res) => res.json()
  );
  promiseCache.news = promise;
  return promise;
}

export function getLiquidations(options?: RequestInit) {
  return request("/liquidations", options);
}

export function getMyCollections(address: string, options?: RequestInit) {
  return request<number[]>(
    `${my_api_base}/ownscode?address=${address}`,
    options
  );
}
