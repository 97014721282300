import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { utils } from "ethers";
import { useHistory } from "react-router";

// hooks
import useTokenBalance from "src/hooks/useTokenBalance";
import useStakedValueByPid from "src/hooks/useStakedValueByPid";
import useTiny from "src/hooks/useTiny";
import { getDisplayBalance } from "src/utils/formatBalance";
import useApprove from "src/hooks/useApprove";
import useModal from "src/hooks/useModal";
import useHandleTransactionReceipt from "src/hooks/useHandleTransactionReceipt";
import useHandleTxConfirm from "src/hooks/useHandleTxConfirm";

// components
// import BackgroundCard from "src/components/BackgroundCard";
// import { StyledCardContent } from "src/components/CardContent";
import Spacer from "src/components/Spacer";
import DepositModal from "src/components/DepositModal";
import WithdrawModal from "src/components/WithdrawModal";
import ClaimButton from "src/components/ClaimButton";
import ActionButtons from "./ActionButtons";

import farmCard_1 from "src/images/farm_card_1.png";
import farmCard_2 from "src/images/farm_card_2.png";
import button_12 from "src/images/button_12.png";

import { bn0 } from "src/utils/index";
import type { Farm } from "src/contexts/Farms";
import type { PoolStakeInfo } from "src/services";
// import type ERC20 from "src/tiny/ERC20";

type FarmWithStakedValue = Farm & Partial<PoolStakeInfo>;

interface FarmCardProps {
  farm: FarmWithStakedValue;
  onAction?(): void;
}

const FarmCard: React.FC<FarmCardProps> = (props: FarmCardProps) => {
  // console.log("props", props);
  const { farm, onAction } = props;
  // const [startTime, setStartTime] = useState(0);
  const { baseApy, cakeApr, pid, tokenSymbol } = farm;
  // console.log("farm", farm);
  // console.log("lpAddress", lpAddress);
  const history = useHistory();
  const { t } = useTranslation();
  // const language = i18n.language;
  // const isZh = language.includes("zh");
  // const [farmPrice] = useFarmPrice();
  // console.log('language', language);
  // const price = farmPrice?.[tokenSymbol] || 0;
  const tiny = useTiny();
  const handleTransactionReceipt = useHandleTransactionReceipt();
  const handleTxConfirm = useHandleTxConfirm();

  /**
   * 如果有 LP 合约，就是 LP 矿池，否则是单币矿池
   * 为了保持 LP 和 单币的一致性，使用 token 的写法，不然要改很多东西
   */

  // console.log('pid', pid);
  // console.log('tokenSymbol', tokenSymbol);

  const tokenContract = tiny.externalTokens[tokenSymbol];
  // console.log("FarmCard tokenContract", tokenContract);

  const [tokenBalance] = useTokenBalance(tokenContract, tiny.myAccount);

  const [stakedValue, refreshStakedBalance] = useStakedValueByPid(pid);
  // console.log("pid", pid);
  // console.log("stakedValue", stakedValue);
  const stakedBalance = stakedValue?.stakedBalance || bn0;
  const harvestable = stakedValue?.pendingReward || bn0;
  // console.log('pid, stakedBalance', pid, stakedBalance);
  // console.log("tiny.contracts", tiny.contracts);

  // approve 的情况
  const [approveStatus, approve] = useApprove(
    tokenContract,
    tiny.contracts.YieldFarming.address
  );
  // console.log("approveStatus", approveStatus);
  const [onPresentDeposit, onDismissDeposit] = useModal(
    <DepositModal
      max={tokenBalance}
      onConfirm={(amount) => {
        console.log("amount", amount);
        const amountBn = utils.parseUnits(amount, 18);
        // console.log('amountBn', amountBn);
        const funcName =
          tokenSymbol === "BNB"
            ? "stakeBNBToYieldFarming"
            : "stakeTokenToYieldFarming";
        handleTransactionReceipt(
          tiny[funcName](pid, amountBn),
          `${t("Deposit")} ${amount} ${tokenSymbol}`
        ).then((tx) => {
          if (tx) {
            return handleTxConfirm(tx.hash).then(() => {
              refreshStakedBalance();
            });
          }
        });
        onDismissDeposit();
      }}
      tokenName={tokenSymbol}
      showFee
      showDesc
    />,
    [tokenBalance]
  );

  const [onPresentWithdraw, onDismissWithdraw] = useModal(
    <WithdrawModal
      showFeesTable={true}
      max={stakedBalance}
      onConfirm={(amount) => {
        // console.log("amount", amount);
        const amountBn = utils.parseUnits(amount, 18);
        // console.log("amountBn", amountBn);
        handleTransactionReceipt(
          tiny.unstakeTokenFromYieldFarming(pid, amountBn),
          `${t("Withdraw")} ${amount} ${tokenSymbol}`
        ).then((tx) => {
          if (tx) {
            return handleTxConfirm(tx.hash).then(() => {
              refreshStakedBalance();
            });
          }
        });
        onDismissWithdraw();
      }}
      tokenName={tokenSymbol}
    />,
    [stakedBalance]
  );

  const toLPFarm = useCallback(() => history.push("/tinyvault/LP"), [history]);

  const harvest = useCallback(() => {
    if (pid === -1) {
      return toLPFarm();
    }
    handleTransactionReceipt(
      tiny.stakeTokenToYieldFarming(pid, bn0),
      t("Harvest")
    ).then((tx) => {
      if (tx) {
        return handleTxConfirm(tx.hash).then(() => {
          refreshStakedBalance();
        });
      }
    });
  }, [
    tiny,
    handleTransactionReceipt,
    refreshStakedBalance,
    pid,
    toLPFarm,
    handleTxConfirm,
    t,
  ]);

  // const renderer = (countdownProps: CountdownRenderProps) => {
  //   const { hours, minutes, seconds } = countdownProps;
  //   const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  //   const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  //   const paddedHours = hours < 10 ? `0${hours}` : hours;
  //   return (
  //     <span style={{ width: "100%" }}>
  //       {paddedHours}:{paddedMinutes}:{paddedSeconds}
  //     </span>
  //   );
  // };

  // const myTvl = Number(getDisplayBalance(stakedBalance)) * price;

  return (
    <StyledContainer>
      <StyledCard src={pid === -1 ? farmCard_1 : farmCard_2} alt="" />
      <StyledContent>
        <StyledTopContainer>
          <img src={farm.icon} width={36} alt="icon" />
          <StyledName className="ff-black">{farm.name}</StyledName>
          <StyledAPY className="fw-500">
            <span>APY </span>
            <span>
              {baseApy !== undefined
                ? baseApy === 0
                  ? "--"
                  : `${Number(baseApy.toFixed(2)).toLocaleString("en-US")}%`
                : "Loading ..."}
            </span>
          </StyledAPY>
        </StyledTopContainer>
        <StyledMultiplying
          className="ff-black"
          style={pid === -1 ? { color: "white" } : {}}
        >
          {farm.multiplying === 0 ? "" : `${farm.multiplying}X`}
        </StyledMultiplying>
        <StyledBottomContainer>
          <div>
            <span>{t("allStaked")}: </span>
            <span>
              {farm.tvl !== undefined
                ? farm.tvl === 0
                  ? "--"
                  : `$${farm.tvl.toLocaleString("en-US")}`
                : "Loading ..."}
            </span>
          </div>
          <div className="mt-5">
            <span>{t("MyStake")}: </span>
            <span>{getDisplayBalance(stakedBalance)}</span>
          </div>

          <div className="mt-15 text-center">
            <div className="mb-5 special-ff">{t("waitingForClaim")}</div>
            <ClaimButton onClick={harvest} className="ff-game rfs-11">
              {getDisplayBalance(harvestable, 18, 2)}
            </ClaimButton>
          </div>

          <Spacer />
          <StyledLiner />
          <div className="display-flex flex-center">
            {pid === -1 ? (
              <StyledGoImg
                src={button_12}
                height="36.5"
                alt="GO"
                onClick={toLPFarm}
              />
            ) : (
              <ActionButtons
                approveStatus={approveStatus}
                approve={approve}
                onPresentDeposit={onPresentDeposit}
                onPresentWithdraw={onPresentWithdraw}
              />
            )}
          </div>
        </StyledBottomContainer>
      </StyledContent>
      {/* {cakeApr && (
        <StyledPrice className="fw-500">
          <span>cakeAPY </span>
          <span>
            {cakeApr !== undefined
              ? cakeApr.cakeRewardsApr === 0
                ? "--"
                : `${cakeApr.cakeRewardsApr.toLocaleString("en-US")}%`
              : "Loading ..."}
          </span>
        </StyledPrice>
      )} */}
    </StyledContainer>
  );
};

export const GoButton: React.FC<{ height?: number }> = ({ height = 36.5 }) => {
  const history = useHistory();
  const toLPFarm = useCallback(() => history.push("/tinyvault/LP"), [history]);

  return (
    <StyledGoImg src={button_12} height={height} alt="GO" onClick={toLPFarm} />
  );
};

export default FarmCard;

const StyledContainer = styled.div`
  position: relative;
  width: 330px;
`;

const StyledCard = styled.img`
  width: 100%;
`;

const StyledName = styled.div`
  font-size: 2rem;
  margin-top: ${(props) => props.theme.spacing[2]}px;
`;

const StyledAPY = styled.div`
  font-size: 1.4rem;
  margin-top: ${(props) => props.theme.spacing[1]}px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0px;
  width: 100%;
  position: absolute;
  padding: 19px 0;
`;

const StyledTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 148px;
  justify-content: center;
  color: white;
`;

const StyledMultiplying = styled.div`
  height: 26px;
  margin-top: 14px;
  padding-top: 3px;
  font-size: 2rem;
  line-height: 1rem;
  font-weight: bold;
  text-align: center;
  color: #514b45;
`;

const StyledBottomContainer = styled.div`
  height: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledLiner = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  border-top: 1px solid #707070;
`;

const StyledGoImg = styled.img`
  cursor: pointer;
`;
