import { useState, useCallback, useEffect } from "react";
import styled from "styled-components";

import useModal from "src/hooks/useModal";
import NewsModal from "./NewsModal";

import labaPng from "../../images/laba.png";
import { getNews, NewData } from "src/services/index";
import BetaNoticeModal from "../BetaModal";

const cacheKey = "NEWS_VERSION";

export default function Notification() {
  const [newsData, setData] = useState<{
    version: number;
    data: NewData[];
  }>();

  const [hasTips, setHasTips] = useState(false);
  const [showNewsModal] = useModal(<NewsModal data={newsData?.data} />, [
    newsData,
  ]);
  const [showBetaNotice] = useModal(<BetaNoticeModal />);

  /* const handleShowNews = useCallback(() => {
    console.log(newsData);

    if (!newsData) return;
    showNewsModal();
    setHasTips(false);
    localStorage.setItem(cacheKey, newsData.version.toString());
  }, [showNewsModal, newsData]); */
  const handleShowNews = () => {
    showBetaNotice()
  }

  useEffect(() => {
    getNews()
      .then((res) => {
        // console.log("res", res);
        setData(res);
        if (localStorage.getItem(cacheKey) === res.version.toString()) {
          // 和本地缓存一致
          return;
        }
        setHasTips(true);
        // const { version, data } = res;
      })
      .catch((err) => console.error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyldLabaBox hasTips={hasTips} onClick={handleShowNews}>
      <img src={labaPng} height="100%" alt="" />
    </StyldLabaBox>
  );
}

const StyldLabaBox = styled.div<{ hasTips?: boolean }>`
  position: relative;
  margin-right: 10px;
  height: 2rem;
  cursor: pointer;
  ::after {
    display: ${(props) => (props.hasTips ? "block" : "none")};
    content: "";
    position: absolute;
    top: 0;
    right: -2px;
    background-color: red;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    border: 2px solid #333;
  }
`;
