// import { ContractName, TokenStat, TreasuryAllocationTime } from "./types";
import { BigNumber, Contract, ethers, Overrides } from "ethers";
// import { decimalToBalance } from "./ether-utils";
import ERC20 from "./ERC20";
// import { getDisplayBalance } from "../utils/formatBalance";
import { getDefaultProvider } from "../utils/provider";
import contractInfoMap from "./contractInfoMap";
import { getNFTOwnerflag } from "./utils";
import { supportedPools, BasePoolInfo } from "./farms";
import type { TransactionResponse } from "@ethersproject/providers";
import type { NFTInfo } from "src/services/typings";
import type { Configuration } from "../config";
// import { promiseCache } from '../services';
const UNIV2PairAbi = require("./abis/uni_v2_lp.json");
const StrategyAbi = require("./abis/IStrategy.json");

// console.log('depositAbi', depositAbi);

export class Tiny {
  myAccount?: string;
  provider: ethers.providers.Web3Provider;
  signer?: ethers.Signer;
  config: Configuration;
  contracts: { [name: string]: Contract };
  externalTokens: { [name: string]: ERC20 };
  boardroomVersionOfUser?: string;
  pools: (BasePoolInfo & {
    lpContract?: Contract;
  })[];

  constructor(cfg: Configuration) {
    const { externalTokens } = cfg;
    const provider = getDefaultProvider();

    this.contracts = {
      farmStrategy: new Contract(
        "0x0739ed7eDae02a85B3E51708B4465840FA6eCd34",
        StrategyAbi,
        provider
      ),
    };

    for (const [name, deployment] of Object.entries(contractInfoMap)) {
      this.contracts[name] = new Contract(
        deployment.address,
        deployment.abi,
        provider
      );
    }

    this.externalTokens = {};
    for (const [symbol, address] of Object.entries(externalTokens)) {
      this.externalTokens[symbol] = new ERC20(address, provider, symbol, 18);
    }

    this.pools = supportedPools.map((pool) => {
      const lpAddress = pool.lpAddress;
      if (lpAddress) {
        return Object.assign(pool, {
          lpContract: new Contract(lpAddress, UNIV2PairAbi, provider),
        });
      }
      return pool;
    });

    // console.log('this.externalTokens', this.externalTokens);

    this.config = cfg;
    this.provider = provider;
  }

  /**
   * @param provider From an unlocked wallet. (e.g. Metamask)
   * @param account An address of unlocked wallet account.
   */
  unlockWallet(provider: any, account: string) {
    const newProvider = new ethers.providers.Web3Provider(
      provider,
      this.config.chainId
    );

    this.signer = newProvider.getSigner(0);
    this.myAccount = account;
    for (const [name, contract] of Object.entries(this.contracts)) {
      this.contracts[name] = contract.connect(this.signer);
    }
    const tokens = Object.values(this.externalTokens);
    for (const token of tokens) {
      token.connect(this.signer);
    }

    for (const pool of this.pools) {
      if (pool.lpContract) {
        pool.lpContract = pool.lpContract.connect(this.signer);
      }
    }

    console.log(`🔓 Wallet is unlocked. Welcome, ${account}!`);
  }

  get isUnlocked(): boolean {
    return !!this.myAccount;
  }

  gasOptions(gas: BigNumber): Overrides {
    const multiplied = Math.floor(
      gas.toNumber() * this.config.gasLimitMultiplier
    );
    console.log(`⛽️ Gas multiplied: ${gas} -> ${multiplied}`);
    return {
      gasLimit: BigNumber.from(multiplied),
    };
  }

  /**
   * 质押 TINC
   * @param {BigNumber} amount
   * @returns
   */
  async depositTINC(amount: BigNumber): Promise<TransactionResponse> {
    const { TinyGateway } = this.contracts;
    const gas = await TinyGateway.estimateGas.depositTINC(amount);
    return TinyGateway.depositTINC(amount, this.gasOptions(gas));
  }

  /**
   * 取消质押 TINC
   * @param {BigNumber} amount
   * @returns
   */
  async withdrawTINC(amount: BigNumber): Promise<TransactionResponse> {
    const { TinyGateway } = this.contracts;
    const gas = await TinyGateway.estimateGas.withdrawTINC(amount);
    return TinyGateway.withdrawTINC(amount, this.gasOptions(gas));
  }

  /**
   * 质押 NFT 到 NFT 矿池加速挖矿
   * @param {number|string} id
   */
  async stakeNFTToFarm(id: number | string): Promise<TransactionResponse> {
    const farm = this.contracts.TinyLPFarm;
    // const alpacaCore = this.contracts.AlpacaCore;
    const nftContract = this.contracts.TinyNFT;
    const from = this.myAccount;
    // console.log("nftContract.address", nftContract.address);
    // console.log("from", from);
    // console.log("id", id);
    // const nftBalance: BigNumber = await nftContract.balanceOf(from, id);
    // console.log("nftBalance", nftBalance.toNumber());

    const to = farm.address;
    const data = "0x00";
    const gas = await nftContract.estimateGas.safeTransferFrom(
      from,
      to,
      id,
      1,
      data
    );
    return nftContract.safeTransferFrom(
      from,
      to,
      id,
      1,
      data,
      this.gasOptions(gas)
    );
  }

  async retrieveNFTFromFarm(): Promise<TransactionResponse> {
    const farm = this.contracts.TinyLPFarm;
    const gas = await farm.estimateGas.retrieve();
    return farm.retrieve(this.gasOptions(gas));
  }

  /**
   * 单个质押 NFT 到 NFTFarming 合约挖矿
   * @param id
   */
  async stakeNFTToNFTFarming(
    id: number | string
  ): Promise<TransactionResponse> {
    const nftContract = this.contracts.TinyNFT;
    const squadContract = this.contracts.TinyNFTFarm;
    // console.log("nftContract", nftContract);
    // console.log("squadContract.address", squadContract.address);
    const from = this.myAccount;
    const to = squadContract.address;
    const data = "0x00";
    const gas = await nftContract.estimateGas.safeTransferFrom(
      from,
      to,
      id,
      1,
      data
    );
    return nftContract.safeTransferFrom(
      from,
      to,
      id,
      1,
      data,
      this.gasOptions(gas)
    );
  }

  /**
   * 批量质押 NFT 到 NFTFarming 合约批量挖矿
   * @param ids
   */
  async stakeNFTsToNFTFarming(
    ids: (number | string)[]
  ): Promise<TransactionResponse> {
    const nftContract = this.contracts.TinyNFT;
    const squadContract = this.contracts.TinyNFTFarm;
    // console.log("nftContract", nftContract);
    // console.log("squadContract.address", squadContract.address);

    const from = this.myAccount;
    const to = squadContract.address;
    const amounts = ids.map(() => 1);
    const data = "0x00";
    const gas = await nftContract.estimateGas.safeBatchTransferFrom(
      from,
      to,
      ids,
      amounts,
      data
    );
    return nftContract.safeBatchTransferFrom(
      from,
      to,
      ids,
      amounts,
      data,
      this.gasOptions(gas)
    );
  }

  async retrieveNFTFromNFTFarming(ids: number[]): Promise<TransactionResponse> {
    const squad = this.contracts.TinyNFTFarm;
    const gas = await squad.estimateGas.retrieve(ids);
    return squad.retrieve(ids, this.gasOptions(gas));
  }

  // 质押 LP 到 farm
  async depositLPToFarm(amount: BigNumber): Promise<TransactionResponse> {
    // console.log('');
    const farm = this.contracts.TinyLPFarm;
    const gas = await farm.estimateGas.deposit(amount);
    return farm.deposit(amount, this.gasOptions(gas));
  }

  // 提取 LP 到 farm
  async withdrawLPFromFarm(amount: BigNumber): Promise<TransactionResponse> {
    const farm = this.contracts.TinyLPFarm;
    const gas = await farm.estimateGas.withdraw(amount);
    return farm.withdraw(amount, this.gasOptions(gas));
  }

  // 收获 NFT 小分队的收益
  async claimSquadRewards(): Promise<TransactionResponse> {
    const squadContract = this.contracts.TinyNFTFarm;
    const gas = await squadContract.estimateGas.claim();
    return squadContract.claim(this.gasOptions(gas));
  }

  // 质押资金到机枪池
  async stakeTokenToYieldFarming(
    pid: number,
    amount: BigNumber
  ): Promise<TransactionResponse> {
    const YieldFarming = this.contracts.YieldFarming;
    // console.log("pid", pid);
    // console.log("amount", amount);
    // console.log("amount.toString()", amount.toString());
    const gas = await YieldFarming.estimateGas.deposit(pid, amount);
    return YieldFarming.deposit(pid, amount, this.gasOptions(gas));
  }

  async stakeBNBToYieldFarming(
    pid: number,
    amount: BigNumber
  ): Promise<TransactionResponse> {
    const YieldFarming = this.contracts.YieldFarming;
    const gas = await YieldFarming.estimateGas.depositBNB(pid, {
      value: amount,
    });
    return YieldFarming.depositBNB(pid, {
      ...this.gasOptions(gas),
      value: amount,
    });
  }

  async unstakeTokenFromYieldFarming(
    pid: number,
    amount: BigNumber
  ): Promise<TransactionResponse> {
    const masterChef = this.contracts.YieldFarming;
    const gas = await masterChef.estimateGas.withdraw(pid, amount);
    return masterChef.withdraw(pid, amount, this.gasOptions(gas));
  }

  // TODO: 1 池挖矿奖励，一次性领取
  async claimAllRewards(pids: number[]): Promise<TransactionResponse> {
    const yieldFarmingContract = this.contracts.YieldFarming;
    const gas = await yieldFarmingContract.estimateGas.getRewards(pids);
    return yieldFarmingContract.getRewards(pids, this.gasOptions(gas));
  }

  // 锁仓的 TINC 数量
  async getLockedTINCBalance(account: string): Promise<BigNumber> {
    const lockedPosition = this.contracts.LockedPosition;
    return lockedPosition.getTINCBalance(account);
  }

  /** ------------------ 盲盒系列 -------------------- */
  // 日常盲盒，使用 TINC 买盲盒
  async buyBlindBoxDaily(
    number: number | string
  ): Promise<TransactionResponse> {
    const BoxContract = this.contracts.TinyNFTBlindBoxDaily;
    const gas = await BoxContract.estimateGas.buyBox(number);
    return BoxContract.buyBox(number, this.gasOptions(gas));
  }

  // 日常盲盒，开盲盒
  async openBlindBoxDaily(count: number): Promise<TransactionResponse> {
    const TinyNFTBlindBoxContract = this.contracts.TinyNFTBlindBoxDaily;
    const gas = await TinyNFTBlindBoxContract.estimateGas.openBox(count);
    return TinyNFTBlindBoxContract.openBox(count, this.gasOptions(gas));
    // return TinyNFTBlindBoxContract.openBox(count, this.gasOptions(BigNumber.from(300000)));
  }

  /** ------------------ 盲盒预售系列 -------------------- */
  // 预售盲盒，使用 BNB 买盲盒
  async buyBlindBoxPresale(
    number: number | string,
    price: BigNumber
  ): Promise<TransactionResponse> {
    const TinyNFTBlindBoxContract = this.contracts.TinyNFTBlindBoxPresale;
    const totalPrice = price.mul(number);
    const gas = await TinyNFTBlindBoxContract.estimateGas.buyBox(number, {
      value: totalPrice,
    });
    return TinyNFTBlindBoxContract.buyBox(number, {
      ...this.gasOptions(gas),
      value: totalPrice,
    });
  }

  // 预售盲盒，开盲盒
  async openBlindBoxPresale(count: number): Promise<TransactionResponse> {
    if (count > 10) {
      count = 10;
    }
    const TinyNFTBlindBoxContract = this.contracts.TinyNFTBlindBoxPresale;
    const gas = await TinyNFTBlindBoxContract.estimateGas.openBox(count);
    return TinyNFTBlindBoxContract.openBox(count, this.gasOptions(gas));
  }

  /**
   * 升级 NFT
   * _ownerflag 目标英雄所在的位置：0--NFT在钱包地址，1--NFT在质押挖矿，2--NFT在LP挖矿，默认0
   * @param id
   * @param nfts
   * @returns
   */
  async upgradeNFT(
    id: number | string,
    ownerAddress: string,
    nfts: NFTInfo[]
  ): Promise<TransactionResponse> {
    // const { id, ownerAddress } = nft;
    const ids: number[] = [];
    const status: number[] = [];
    nfts.forEach((ele) => {
      ids.push(ele.id);
      const _ownerflag = getNFTOwnerflag(ele.ownerAddress);
      status.push(_ownerflag);
    });

    const flag = getNFTOwnerflag(ownerAddress);
    // console.log("id, flag, ids, status", id, flag, ids, status);

    const upgradeContract = this.contracts.TinyNFTLogic;
    const gas = await upgradeContract.estimateGas.levelUpHero(
      id,
      flag,
      ids,
      status
    );
    return upgradeContract.levelUpHero(
      id,
      flag,
      ids,
      status,
      this.gasOptions(gas)
    );
  }

  // 领取空投的方法
  async claimAirdrop(): Promise<TransactionResponse> {
    const airdropContract = this.contracts.TinyAirdrop;
    const gas = await airdropContract.estimateGas.claim();
    return airdropContract.claim(this.gasOptions(gas));
  }

  /** ---------------- 交易市场相关 ------------------ */

  /**
   * 出售 NFT
   * @param {number} ids
   * @param {number[]} ids
   * @param {BigNumber} _startingPrice
   * @param {BigNumber} _endingPrice
   * @param {number} _duration 时间，单位秒。默认值是 1 天
   * @returns
   */
  async createAuction(
    tokenId: number,
    ids: number[],
    _startingPrice: BigNumber,
    _endingPrice: BigNumber,
    _duration = 86400
  ): Promise<TransactionResponse> {
    const marketPlace = this.contracts.MarketPlace;
    const gas = await marketPlace.estimateGas.createAuction(
      _startingPrice,
      _endingPrice,
      _duration,
      tokenId,
      ids
    );
    // console.log("gas", gas);

    return marketPlace.createAuction(
      _startingPrice,
      _endingPrice,
      _duration,
      tokenId,
      ids,
      this.gasOptions(gas)
    );
  }

  /**
   * NFT 修改价格
   * @param {number} ids
   * @param {number[]} ids
   * @param {BigNumber} _startingPrice
   * @param {BigNumber} _endingPrice
   * @param {number} _duration 时间，单位秒。默认值是 1 天
   * @returns
   */
  async changeAuctionPrice(
    auctionId: number,
    _startingPrice: BigNumber,
    _endingPrice: BigNumber,
    _duration = 86400
  ): Promise<TransactionResponse> {
    const marketPlace = this.contracts.MarketPlace;
    const gas = await marketPlace.estimateGas.changePrice(
      auctionId,
      _startingPrice,
      _endingPrice,
      _duration
    );
    // console.log("gas", gas);

    return marketPlace.changePrice(
      auctionId,
      _startingPrice,
      _endingPrice,
      _duration,
      this.gasOptions(gas)
    );
  }

  /**
   * 竞拍
   * @param id
   * @param bidPrice
   * @returns
   */
  async bid(id: number, bidPrice: BigNumber) {
    const marketPlace = this.contracts.MarketPlace;
    // const price = await marketPlace.getCurrentPrice(id);
    const gas = await marketPlace.estimateGas.bid(id, bidPrice);
    return marketPlace.bid(id, bidPrice, this.gasOptions(gas));
  }

  // 取消拍卖
  async cancelAuction(auctionId: number): Promise<TransactionResponse> {
    const marketPlace = this.contracts.MarketPlace;
    const gas = await marketPlace.estimateGas.cancelAuction(auctionId);
    return marketPlace.cancelAuction(auctionId, this.gasOptions(gas));
  }

  /// 预售交易
  // 预售出售
  async createRuneAuction(
    _price: BigNumber,
    tokenId: number,
    amount: number,
  ): Promise<TransactionResponse> {
    const runeMarket = this.contracts.RuneMarket;
    const gas = await runeMarket.estimateGas.createAuction(
      _price,
      tokenId,
      amount
    );
    // console.log("gas", gas);

    return runeMarket.createAuction(
      _price,
      tokenId,
      amount,
      this.gasOptions(gas)
    );
  }

  // 预售改价
  async changeRuneAuctionPrice(
    auctionId: number,
    _price: BigNumber,
  ): Promise<TransactionResponse> {
    const runeMarket = this.contracts.RuneMarket;
    const gas = await runeMarket.estimateGas.changePrice(
      auctionId,
      _price,
    );
    // console.log("gas", gas);

    return runeMarket.changePrice(
      auctionId,
      _price,
      this.gasOptions(gas)
    );
  }

  // 预售竞拍
  async runeBid(auctionId: number, bidPrice: BigNumber) {
    const runeMarket = this.contracts.RuneMarket;
    // const price = await marketPlace.getCurrentPrice(id);
    const gas = await runeMarket.estimateGas.bid(auctionId, bidPrice);
    return runeMarket.bid(auctionId, bidPrice, this.gasOptions(gas));
  }

  // 取消预售拍卖
  async cancelRuneAuction(auctionId: number): Promise<TransactionResponse> {
    const runeMarket = this.contracts.RuneMarket;
    const gas = await runeMarket.estimateGas.cancelAuction(auctionId);
    return runeMarket.cancelAuction(auctionId, this.gasOptions(gas));
  }

  /** 质押到游戏相关的操作 */

  // 将 NFT 充到游戏中
  async depositNFTToGaming(
    tokenId: number | string
  ): Promise<TransactionResponse> {
    const nftContract = this.contracts.TinyNFT;
    const gatewayContract = this.contracts.TinyGateway;
    console.log("nftContract", nftContract);
    console.log("this.myAccount", this.myAccount);
    // console.log("gatewayContract.address", gatewayContract.address);
    console.log("tokenId", tokenId);
    const gas = await nftContract.estimateGas.safeTransferFrom(
      this.myAccount,
      gatewayContract.address,
      tokenId,
      1,
      "0x00"
    );
    console.log("gas", gas.toNumber());

    return nftContract
      .safeTransferFrom(
        this.myAccount,
        gatewayContract.address,
        tokenId,
        1,
        "0x00",
        this.gasOptions(gas)
      )
      .then((res: any) => {
        // console.log("res", res);
        return res;
      });
  }

  async batchDepositNFTsToGaming(ids: number[]): Promise<TransactionResponse> {
    const nftContract = this.contracts.TinyNFT;
    const gatewayContract = this.contracts.TinyGateway;
    console.log("nftContract", nftContract);
    console.log("this.myAccount", this.myAccount);
    // console.log("gatewayContract.address", gatewayContract.address);
    // console.log("tokenId", tokenId);
    const amounts = new Array(ids.length).fill(1);
    return nftContract.safeBatchTransferFrom(
      this.myAccount,
      gatewayContract.address,
      ids,
      amounts,
      "0x00"
    );
  }

  async retrieveNFTFromSquad(ids: number[]): Promise<TransactionResponse> {
    const gatewayContract = this.contracts.TinyGateway;
    if (ids.length > 1) {
      const gas = await gatewayContract.estimateGas.batchRetrieveNFT(ids);
      return gatewayContract.batchRetrieveNFT(ids, this.gasOptions(gas));
    } else {
      const id = ids[0];
      const gas = await gatewayContract.estimateGas.withdrawNFT(id);
      return gatewayContract.withdrawNFT(id, this.gasOptions(gas));
    }
  }

  // buy presale runes
  async orderPresaleRune(value: number, price: BigNumber): Promise<TransactionResponse> {
    const presale = this.contracts.TinyRunePresale;
    const gas = await presale.estimateGas.order(value, {
      value: price.mul(value),
    });
    return presale.order(value, {
      ...this.gasOptions(gas),
      value: price.mul(value),
    });
  }

  // buy presale runes
  async redeemRune(): Promise<TransactionResponse> {
    const presale = this.contracts.TinyRunePresale;
    const gas = await presale.estimateGas.redeem();
    return presale.redeem(this.gasOptions(gas));
  }

  async unsealPresaleRune(amount: number): Promise<TransactionResponse> {
    const TinyNFTBlindBoxContract = this.contracts.TinyNFTBlindBoxDaily;
    const gas = await TinyNFTBlindBoxContract.estimateGas.unsealPresaleRune(amount);
    return TinyNFTBlindBoxContract.unsealPresaleRune(amount, this.gasOptions(gas));
  }
}
