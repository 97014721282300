import { BigNumber, ethers } from "ethers";
import { useCallback, useMemo } from "react";
import {
  useHasPendingApproval,
  useTransactionAdder,
} from "../state/transactions/hooks";
import useAllowance from "./useAllowance";
import useTxModal from "./useTxModal";
import ERC20 from "../tiny/ERC20";
import config from "../config";
import type { TransactionResponse } from "@ethersproject/providers";

const APPROVE_AMOUNT = ethers.constants.MaxUint256;
const APPROVE_sKDOE_AMOUNT = BigNumber.from("1000000000000000000000000");
const originTokenAddress = config.externalTokens.BNB;

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
function useApprove(
  token: ERC20,
  spender: string
): [ApprovalState, () => Promise<void | TransactionResponse>] {
  const pendingApproval = useHasPendingApproval(token.address, spender);
  // console.log('pendingApproval', pendingApproval);
  const currentAllowance = useAllowance(token, spender, pendingApproval);
  // console.log('currentAllowance', currentAllowance);
  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    // if platform token, no approve
    if (token.address === originTokenAddress) return ApprovalState.APPROVED;
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN;

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lt(APPROVE_sKDOE_AMOUNT)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED;
  }, [currentAllowance, pendingApproval, token.address]);

  const addTransaction = useTransactionAdder();

  const show = useTxModal();

  const approve = useCallback(async (): Promise<void | TransactionResponse> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error("approve was called unnecessarily");
      return;
    }
    // console.log("token", token);
    show();
    try {
      const response = await token.approve(spender, APPROVE_AMOUNT);
      show("confirm");
      // console.log("response", response);
      addTransaction(response, {
        summary: `Approve ${token.symbol}`,
        approval: {
          tokenAddress: token.address,
          spender: spender,
        },
      });
      return response;
    } catch (error: any) {
      console.error(error);
      if (error) {
        if (error.code === 4001) {
          show("cancel"); // 说明是拒绝了该交易
        } else {
          show("error");
        }
      } else {
        show("error");
      }
    }
  }, [approvalState, token, spender, addTransaction, show]);

  return [approvalState, approve];
}

export default useApprove;
