import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import zh from "./zh-CN.json";
import en from "./en.json";
import ru from "./ru.json";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: true,
    },
    resources: {
      en: {
        translation: en,
      },
      zh: {
        translation: zh,
      },
      ru: {
        translation: ru,
      },
    },
    fallbackLng: "en",
    keySeparator: false,
    interpolation: { escapeValue: false },
  });

i18next.on("languageChanged", function (lng: "en" | "zh" | "ru") {
  // console.log("lng", lng);
  document.body.classList.value = lng;
});

export default i18next;
