import React, { createContext, useCallback, useState } from "react";
import styled from "styled-components";
import { noFunc } from "src/utils";

interface ModalsContext {
  content?: React.ReactNode;
  isOpen?: boolean;
  onPresent: (content: React.ReactNode, props?: any) => void;
  onTxModalPresent: (content: React.ReactNode, props?: any) => void;
  onDismiss: () => void;
  onTxModalDismiss: () => void;
}

export const Context = createContext<ModalsContext>({
  onPresent: noFunc,
  onTxModalPresent: noFunc,
  onDismiss: noFunc,
  onTxModalDismiss: noFunc,
});

const Modals: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [content, setContent] = useState<React.ReactNode>();
  const [txConfirmContent, setTxComfirmContent] = useState<React.ReactNode>();
  const [rest, setRest] = useState<any>();
  const [txRest, setTxRest] = useState<any>();

  const handlePresent = useCallback(
    (modalContent: React.ReactNode, rest?: any) => {
      // console.log('modalContent', modalContent);
      // console.log('rest', rest);
      setContent(modalContent);
      setRest(rest);
      setIsOpen(true);
    },
    []
  );

  const handleDismiss = useCallback(() => {
    setContent(undefined);
    setRest(undefined);
    setIsOpen(false);
  }, []);

  const handleTxModalPresent = useCallback(
    (modalContent: React.ReactNode, rest?: any) => {
      // console.log('modalContent', modalContent);
      setTxComfirmContent(modalContent);
      setTxRest(rest);
      setIsConfirmModalOpen(true);
    },
    []
  );

  const handleTxModalDismiss = useCallback(() => {
    setTxComfirmContent(undefined);
    setTxRest(undefined);
    setIsConfirmModalOpen(false);
  }, []);

  return (
    <Context.Provider
      value={{
        content,
        isOpen,
        onPresent: handlePresent,
        onTxModalPresent: handleTxModalPresent,
        onDismiss: handleDismiss,
        onTxModalDismiss: handleTxModalDismiss,
      }}
    >
      {children}
      {isOpen && (
        <StyledModalWrapper>
          <StyledModalBackdrop onClick={handleDismiss} />
          {React.isValidElement(content) &&
            React.cloneElement(content, {
              onDismiss: handleDismiss,
              ...rest,
            })}
        </StyledModalWrapper>
      )}
      {isConfirmModalOpen && (
        <StyledTxConfirmModalWrapper>
          <StyledTxConfirmModalBackdrop onClick={handleTxModalDismiss} />
          {React.isValidElement(txConfirmContent) &&
            React.cloneElement(txConfirmContent, {
              onDismiss: handleTxModalDismiss,
              ...txRest,
            })}
        </StyledTxConfirmModalWrapper>
      )}
    </Context.Provider>
  );
};

const StyledModalWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
`;

const StyledTxConfirmModalWrapper = styled(StyledModalWrapper)`
  z-index: 111;
`;

const StyledModalBackdrop = styled.div`
  background-color: #00000088;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

const StyledTxConfirmModalBackdrop = styled(StyledModalBackdrop)`
  z-index: 0;
`;

export default Modals;
