import { useCallback, useState } from "react";
import { BigNumber, Contract } from "ethers";

import useAutoFreshRequest from "src/hooks/useAutoFreshRequest";
import ERC20 from "../tiny/ERC20";
import { bn0 } from "src/utils";

const cache: {
  [tokenAddress: string]: {
    [address: string]: string | undefined;
  };
} = {};

function getCache(tokenAddress: string, address: string | null | undefined) {
  if (!address) return bn0;
  if (!cache[tokenAddress]) return bn0;
  const cacheBalanceStr = cache[tokenAddress][address];
  if (!cacheBalanceStr) return bn0;
  return BigNumber.from(cacheBalanceStr);
}

const useTokenBalance: (
  token: ERC20 | Contract,
  address: string | null | undefined
) => [BigNumber, () => void] = (token, address) => {
  const [tokenBalance, setBalance] = useState(getCache(token.address, address));

  const fetchBalance = useCallback(
    async (address: string | null | undefined) => {
      if (!address) return;
      try {
        let balance = await token.balanceOf(address);
        setBalance(balance);
        if (cache[token.address] === undefined) {
          cache[token.address] = {};
        }
        cache[token.address][address] = balance.toString();
      } catch (error) {
        console.error("fetchBalance error:", error);
      }
    },
    [token]
  );

  const [_, refreshBalance] = useAutoFreshRequest(fetchBalance, [address], {
    interval: 600_000,
  });

  return [tokenBalance, refreshBalance];
};

export default useTokenBalance;
