import React, { ReactNode, CSSProperties } from "react";
// import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import Card from "../Card";
import CardContent from "../CardContent";
import Container from "../Container";
import ModalTitle from "../ModalTitle";
import Close from "../Close";

import sparkUp from "src/images/spark_up.png";
import sparkDown from "src/images/spark_down.png";
export interface ModalProps {
  onDismiss?: () => void;
  title?: ReactNode;
  size?: "es" | "sm" | "md" | "lg";
  bgType?: 1 | 2;
  contentClx?: string;
  className?: string;
  isConfirmModal?: boolean;
  style?: CSSProperties;
}

const Modal: React.FC<ModalProps> = ({
  children,
  title,
  size = "es",
  bgType,
  onDismiss,
  contentClx,
  className,
  isConfirmModal = false,
  style,
}) => {
  const sprProps = useSpring({
    to: { opacity: 1, scale: 1 },
    from: { opacity: 0, scale: 0.5 },
  });

  return (
    <Container size={size} className={className} style={style}>
      <AnimatedCard bgType={bgType} style={sprProps}>
        <CardContent className={contentClx + " position-relative"}>
          {isConfirmModal && (
            <>
              <img
                src={sparkUp}
                style={{ position: "absolute", top: -20, left: -25 }}
              />
              <img
                src={sparkDown}
                style={{ position: "absolute", right: -25, bottom: -15 }}
              />
            </>
          )}
          {typeof title !== "undefined" ? <ModalTitle text={title} /> : null}
          {children}
          {onDismiss && <Close onClick={onDismiss} />}
        </CardContent>
      </AnimatedCard>
    </Container>
  );
};

export default Modal;

const AnimatedCard = animated(Card);
