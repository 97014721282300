import React, { CSSProperties } from "react";
import styled from "styled-components";
import BorderImgButton from "../Button/BorderImgButton";
import MaxButton from "src/components/Button/MaxButton";

import Input, { InputProps } from "../Input";
// import { StyledMaxButton } from "../StyledComponents";
interface TokenInputProps extends InputProps {
  max: number | string;
  symbol: string;
  onSelectMax?: () => void;
  min?: number | string;
  startAdornment?: React.ReactNode;
  alignRight?: boolean;
  style?: CSSProperties;
}

const TokenInput: React.FC<TokenInputProps> = ({
  max,
  symbol,
  onChange,
  onSelectMax,
  value,
  min = 0,
  startAdornment,
  alignRight=true,
  style,
}) => {
  // console.log('TokenInput max', max);
  return (
    <StyledTokenInput style={{...style}}>
      <StyledMaxText>
        {max.toLocaleString()} {symbol} Available
      </StyledMaxText>
      <Input
        className={`pr-10${alignRight ? " text-right" : "" }`}
        startAdornment={startAdornment}
        endAdornment={
          <MaxButton onClick={onSelectMax}>MAX</MaxButton>
        }
        onChange={onChange}
        placeholder="0"
        value={value}
        type="number"
        max={max}
        min={min}
      />
    </StyledTokenInput>
  );
};

const StyledTokenInput = styled.div``;

const StyledMaxText = styled.div`
  width: 95%;
  align-items: center;
  color: ${(props) => props.theme.color.grey[500]};
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 32px;
  justify-content: flex-end;
`;

const StyledTokenSymbol = styled.span`
  color: ${(props) => props.theme.color.grey[500]};
  font-weight: 700;
`;

export default TokenInput;
