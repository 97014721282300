import styled from "styled-components";
import { Pagination, Autoplay, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

import ResizedImage from "src/components/ResizedImage"

import whitelist from "src/images/swiper/whitelist_finished.png";
import beta from "src/images/swiper/beta.png";
import ambassador from "src/images/swiper/ambassador.png";
import second_beta from "src/images/swiper/second_beta.png";
import open_test from "src/images/swiper/open_test.png";
import { useTranslation } from "react-i18next";

function Image(props: React.ImgHTMLAttributes<HTMLImageElement>) {
  return <ResizedImage {...props} options={{
    width: 660, height: 330, onerror: "redirect"
  }} />
}

export default function Carousel() {
  const { t } = useTranslation()
  return (
    <StyledCarousel>
      <Swiper
        modules={[Pagination, Autoplay, EffectCoverflow]}
        pagination={{ clickable: true }}
        effect="coverflow"
        centeredSlides={true}
        centeredSlidesBounds={true}
        centerInsufficientSlides={true}
        coverflowEffect={{
          rotate: 10,
          stretch: 0,
          depth: 200,
          modifier: 1,
        }}
        speed={1000}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
          stopOnLastSlide: false,
        }}
        loop={true}
        spaceBetween={0}
        slidesPerView="auto"
      >
        <SwiperSlide>
          <a
            href="https://medium.com/@tinyworld.gamefi/tiny-world-platform-beta-test-event-2350c1d3bd92"
            target="_blank"
            rel="noreferrer"
          >
            <Image src={open_test} alt="Open Test" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href="https://medium.com/@tinyworld.gamefi"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              src={whitelist}
              alt="Whitelist Competetion Finished"
            />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href="https://medium.com/@tinyworld.gamefi"
            target="_blank"
            rel="noreferrer"
          >
            <Image src={beta} alt="Closed Beta Coming" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href="https://twitter.com/tinyworldgamefi/status/1466387404487634946"
            target="_blank"
            rel="noreferrer"
          >
            <Image src={ambassador} alt="Ambassador Recruitment" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href="https://medium.com/@tinyworld.gamefi"
            target="_blank"
            rel="noreferrer"
          >
            <Image src={second_beta} alt="Second Round Of Closed Beta" />
          </a>
        </SwiperSlide>
      </Swiper>
      <More
        href="https://medium.com/@tinyworld.gamefi"
        target="_blank"
        rel="noreferrer"
      >
        {t("MORE")}
      </More>
    </StyledCarousel>
  );
}

const StyledCarousel = styled.div`
  position: relative;
  margin-top: 6rem;
  .swiper {
    height: 380px;
    /* margin: 0 5%; */
    --swiper-theme-color: #fccf4c;
    --swiper-pagination-bullet-inactive-color: #905419;
    --swiper-pagination-bullet-width: 16px;
    --swiper-pagination-bullet-height: 16px;

    .swiper-pagination-bullet {
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      border: 3px #905419 solid;
      box-sizing: border-box;
    }
  }

  .swiper-slide {
    max-height: 330px;
    max-width: 660px;
    img {
      height: 100%;
      border-radius: 4px;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-top: 10px;

    .swiper {
      height: 205px;
      --swiper-pagination-bullet-width: 11px;
      --swiper-pagination-bullet-height: 11px;
    }

    .swiper-slide {
      max-height: 173px;
      max-width: 346px;
    }
  `}
`;

const More = styled.a`
  position: absolute;
  bottom: 30px;
  right: 0;
  color: #514b45;
  z-index: 11;
  ::after {
    content: "►";
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    bottom: 11px;
  `}
`;
