import { useCallback } from "react";
import { useWallet } from "src/use-wallet/index";
import { useTransactionAdder } from "../state/transactions/hooks";
import { useAddPopup } from "../state/application/hooks";
import useTxModal from "./useTxModal";
import { logErrorToService, ErrorBody } from "src/services/util";

import type { TransactionResponse } from "@ethersproject/providers";

function useHandleTransactionReceipt(): (
  promise: Promise<TransactionResponse>,
  summary: string,
  params?: string
) => Promise<TransactionResponse | void> {
  const addTransaction = useTransactionAdder();
  const addPopup = useAddPopup();
  const showTxModal = useTxModal();
  const { account, chainId } = useWallet();

  return useCallback(
    (
      promise: Promise<TransactionResponse>,
      summary: string,
      params?: string
    ) => {
      showTxModal();
      return promise
        .then((tx) => {
          // console.log("tx", tx);
          showTxModal("confirm");
          addTransaction(tx, { summary });
          return tx;
        })
        .catch((err) => {
          if (err.message.includes("User denied")) {
            // User denied transaction signature on MetaMask.
            return showTxModal("cancel");
          }
          // console.log("err", err);
          let stack = err.message || err.stack;
          if (err.data) {
            const data = err.data;
            const exactErrorInfo = data.message;
            if (exactErrorInfo) {
              stack = exactErrorInfo;
            }
          }
          showTxModal("error");
          const message = `Unable to ${summary[0].toLowerCase()}${summary.slice(
            1
          )}`;
          console.error(`${message}: ${stack}`);
          if (account) {
            const body: ErrorBody = {
              account,
              error: stack,
              summary,
              params,
              type: "tx",
              chainId,
            };
            logErrorToService(body);
          }
          addPopup({ error: { message, stack: stack } });
        });
    },
    [addPopup, addTransaction, showTxModal, account, chainId]
  );
}

export default useHandleTransactionReceipt;
