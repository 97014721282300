import { useContext } from "react";
import { AlertCircle, CheckCircle } from "react-feather";
import styled, { ThemeContext } from "styled-components";
import { useTranslation } from "react-i18next";
import { useWallet } from "src/use-wallet/index";

import { AutoColumn } from "../Column";
import { AutoRow } from "../Row";
import config from "../../config";

import check from "src/images/check.png";
import close from "src/images/close.png";

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`;

export default function TransactionPopup({
  hash,
  success,
  summary,
}: {
  hash: string;
  success?: boolean;
  summary?: string;
}) {
  const { chainId } = useWallet();
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        {success ? (
          // <CheckCircle color={theme.color.teal[200]} size={24} />
          <img src={check} alt="" />
        ) : (
          // <AlertCircle color="#FF6871" size={24} />
          <img src={close} alt="" />
        )}
      </div>
      <AutoColumn gap="8px">
        <StyledPopupDesc>
          {summary ?? "Hash: " + hash.slice(0, 8) + "..." + hash.slice(58, 65)}
        </StyledPopupDesc>
        {chainId && (
          <StyledLink href={`${config.scanUrl}/tx/${hash}`} target="_blank">
            {t("View on BscScan")}
          </StyledLink>
        )}
      </AutoColumn>
    </RowNoFlex>
  );
}

const StyledPopupDesc = styled.span`
  font-weight: 500;
  color: ${(props) => props.theme.color.grey[300]};
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.color.grey[500]};
`;
