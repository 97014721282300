import { ButtonHTMLAttributes, PropsWithChildren } from "react";
import styled from "styled-components";
import { formatFontSize } from "../../utils";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: "sm" | "md" | "lg";
  fontSize?: number | string;
  height?: number;
  symbol?: string;
}
export default function ClaimButton(props: PropsWithChildren<ButtonProps>) {
  const { children, size = "md", fontSize, symbol = "TINC", ...rest } = props;

  let width = 116;
  let height = 32;
  // let defaultFontSize = 16;
  switch (size) {
    case "sm":
      width = 105;
      height = 24;
      // defaultFontSize = 14;
      break;
    case "lg":
      width = 128;
      height = 36;
      // defaultFontSize = 17;
      break;
  }
  return (
    <StyledButton
      width={width}
      height={height}
      {...rest}
      fontSize={
        fontSize ? formatFontSize(fontSize) : undefined
      }
    >
      <StyledImg size={size} src="/images/tokens/TINC.png" alt="TINC" />
      <StyledContent>{children}</StyledContent>
      <StyledContent className="ml-5">{symbol}</StyledContent>
    </StyledButton>
  );
}

interface BaseProps {
  width: number;
  height: number;
  fontSize?: string;
  disabled?: boolean;
}
const StyledButton = styled.button<BaseProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 2px 8px 2px 6px;
  min-width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  line-height: ${(props) => props.height}px;
  font-size: ${(props) => props.fontSize ? props.fontSize : null};
  background-image: linear-gradient(#6b6969, #3e3a39);
  border-radius: 20px;
  color: ${(props) => props.theme.color.yellow};
  box-shadow: 0 3px 0 #33333366;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(#3e3a39, #6b6969);
    clip-path: polygon(0 0, 100% 0, 20px 100%, 0 100%);
    border-radius: 20px;
  }
`;

const StyledContent = styled.span`
  position: relative;
  z-index: 1;
`;

const StyledImg = styled.img<{
  size: "sm" | "md" | "lg";
}>`
  position: relative;
  margin-right: 5px;
  height: ${(props) =>
    props.size === "sm" ? "80%" : props.size === "lg" ? "86%" : "82%"};
  z-index: 1;
`;
// 6B6969/3E3A39    323030
