import config from "../config";

const multicallAbi = require("./abis/multicall.json");

const contractAddresses = {
  masterChef: {
    97: "0x96423881DDCa161207EE228790524e4fe8E5c9dD",
    56: "0x73feaa1eE314F8c655E354234017bE2193C9E24E",
  },
  sousChef: {
    56: "0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95",
    97: "0xeBcc46C801284c92AF72afA7840296C022d78c11",
  },
  multiCall: {
    56: "0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B",
    97: "0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576",
  },
};

const contractInfoMap: {
  [key: string]: {
    abi: any;
    address: string;
  };
} = {
  ...config.deployments,
  // TinyAirdrop: TinyAirdrop,
  multicall: {
    abi: multicallAbi,
    address: contractAddresses.multiCall[config.chainId],
  },
};

// console.log('contractInfoMap', contractInfoMap);

export default contractInfoMap;
