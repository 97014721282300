import React from "react";
import styled, { keyframes } from "styled-components";

interface LoaderProps {
  text?: string;
  size?: number;
  className?: string;
  color?: string;
}

const Loader: React.FC<LoaderProps> = ({
  text,
  size = 60,
  className,
  color = "rgb(244 215 162)",
}) => {
  // const width = size, height = size;
  return (
    <StyledLoader className={className}>
      <StyledLoading size={size} color={color}>
        <RotateSvg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="20.625 20.625 41.25 41.25"
        >
          <StyledCircle
            fill="transparent"
            cx="41.25"
            cy="41.25"
            r="19"
            strokeWidth="3"
            strokeDasharray="120"
            strokeDashoffset="120px"
          ></StyledCircle>
        </RotateSvg>
      </StyledLoading>
      {!!text && <StyledText>{text}</StyledText>}
    </StyledLoader>
  );
};

const StyledLoader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledLoading = styled.div<{ size: number; color: string }>`
  font-size: 32px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  position: relative;
  color: ${(props) => props.color};
`;

const RotateSvg = styled.svg`
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  transition: all 0.2s ease-in-out;
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100,200;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100,200;
    stroke-dashoffset: -125px;
  }
`;

const StyledCircle = styled.circle`
  animation: ${dash} 1.4s ease-in-out infinite;
  stroke-linecap: round;
  stroke-dasharray: 80, 200;
  stroke-dashoffset: 0px;
  stroke: currentColor;
  z-index: 2;
  transition: all 0.6s ease-in-out;
`;

const StyledText = styled.div`
  color: ${(props) => props.theme.color.grey[400]};
`;

export default Loader;
