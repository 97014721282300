import React, { useMemo, useCallback } from "react";
import { BigNumber } from "ethers";
import { useQuery } from "react-query";

// import { getYieldFarmingContract, getEarned } from '../../sushi/utils'
import Context, { initState } from "./context";
import useTiny from "../../hooks/useTiny";
import useAutoFreshRequest from "../../hooks/useAutoFreshRequest";
import useAllStakedValue from "../../hooks/useAllStakedValue";
import useTokenBalance from "../../hooks/useTokenBalance";
import useLPStaked from "../../hooks/useLPStaked";

import { getFarmInfos, getLPPoolInfo, LPFarmPowerInfo } from "src/services";
import { getBalance } from "src/utils/formatBalance";
import config from "src/config";

import { bn0, bn1e18 } from "src/utils/index";
// import { Farm } from './types'

const farmCacheKey = "FARM_POOL_INFO";

const Farms: React.FC = ({ children }) => {
  // const [unharvested, setUnharvested] = useState(0);
  const tiny = useTiny();
  // const account = tiny.myAccount;

  const { data: farmInfos } = useQuery(farmCacheKey, getFarmInfos, {
    refetchInterval: 300_000,
  });

  const [allStakedValue, freshAllStakedValue] = useAllStakedValue(
    initState.farms
  );

  const unharvested = useMemo(() => {
    if (!allStakedValue) return bn0;
    return allStakedValue
      .slice(1)
      .reduce((pre, cur) => pre.add(cur.pendingReward), bn0);
  }, [allStakedValue]);

  const lpFarm = tiny.contracts.TinyLPFarm;
  // const lpToken = tiny.externalTokens.LP;
  // console.log('lpToken', lpToken);

  const [lpFarmTotalStaked] = useLPStaked();
  // const [lpFarmTotalStaked] = useTokenBalance(lpToken, account);

  // console.log('lpFarmTotalStaked', lpFarmTotalStaked);

  const lpFarmTVL = useMemo(() => {
    const lpPrice = farmInfos?.price["LP"];
    // console.log('lpPrice', lpPrice);
    if (!lpPrice) return;
    return getBalance(
      lpFarmTotalStaked.mul((lpPrice * 10000).toFixed()).div(10000)
    );
  }, [lpFarmTotalStaked, farmInfos]);

  const [lpFarmPoolInfo, freshInfo] = useAutoFreshRequest(lpFarm.poolInfo, [], {
    interval: 300_000,
  });

  const [lpFarmNFTPowerInfo] = useAutoFreshRequest<LPFarmPowerInfo>(
    getLPPoolInfo,
    [],
    {
      initState: initState.lpFarmNFTPowerInfo,
      interval: 600_000,
    }
  );

  // calc APY with bonus
  const calcApyByBonus = useCallback(
    (NFTBonus: number) => {
      if (!lpFarmPoolInfo) return 0;
      const farmAccShare: BigNumber = lpFarmPoolInfo.accShare;
      // console.log('farmAccShare', farmAccShare);
      if (farmAccShare.isZero()) return 0;
      const price = farmInfos?.price;
      if (!price) return 0;
      const lpPrice = price["LP"];
      // const tincPrice = price.TINC;
      // beta tinc price
      const tincPrice = 0.05;
      // console.log('tincPrice', tincPrice);

      const rodPerSharePerDay = BigNumber.from(
        (config.lpFarmTINCPerDay * 1e10).toFixed()
      )
        .mul(bn1e18)
        .div(farmAccShare);
      // console.log('NFTBonus', NFTBonus);
      // console.log('rodPerSharePerDay', rodPerSharePerDay);
      // console.log('lpPrice', lpPrice);
      const apy = BigNumber.from(NFTBonus)
        .mul(rodPerSharePerDay)
        .mul((365 * tincPrice * 1e4).toFixed())
        .div((lpPrice * 1e4).toFixed())
        .div(1e6) // 上面乘了 1e10 的系数，这里是返回 的是百分比，所以除以 1e8
        .toNumber();
      return apy / 100;
    },
    [lpFarmPoolInfo, farmInfos?.price]
  );

  const pool = lpFarmNFTPowerInfo.pool;
  const stakeInfos = useMemo(() => {
    if (!farmInfos) return;
    if (lpFarmTVL && pool.max_bonus) {
      const lpFarmStakeInfo = {
        pid: -1,
        tvl: lpFarmTVL,
        baseApy: calcApyByBonus(pool.max_bonus + 1000),
      };
      return Object.assign({}, farmInfos.stakeInfos, {
        "-1": lpFarmStakeInfo,
      });
    }
    return farmInfos.stakeInfos;
  }, [farmInfos, lpFarmTVL, pool.max_bonus, calcApyByBonus]);

  const value = {
    farms: initState.farms,
    unharvested,
    stakeInfos,
    allStakedValue: allStakedValue ?? [],
    tvlTotal: farmInfos?.tvlTotal || 0, // 一池的总 TVL
    price: farmInfos?.price || initState.price,
    freshAllStakedValue,
    lpFarmTVL, // 二池的 TVL
    freshLpFarmPoolInfo: freshInfo,
    lpFarmNFTPowerInfo,
    calcApyByBonus,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default Farms;
