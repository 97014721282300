import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BigNumber } from "ethers";

import useIsMobile from "src/hooks/useIsMobile";
import Modal, { ModalProps } from "../Modal";
import ModalActions from "../ModalActions";
import TokenInput from "../TokenInput";
import ParallelogramButton from "../Button/ParallelogramButton";

import {
  getDisplayBalance,
  getFullDisplayBalance,
} from "../../utils/formatBalance";

interface DepositModalProps extends ModalProps {
  max: BigNumber;
  onConfirm: (amount: string) => void;
  tokenName?: string;
  decimals?: number;
  inputMin?: number | string;
  showDesc?: boolean;
  showFee?: boolean;
}

export function FeesTable() {
  const { t } = useTranslation();
  return (
    <>
      <div className="special-ff text-center mb-10 rfs-13">{t("Fees")}</div>
      <StyledTableContainer>
        <StyledHeader>
          <div>{t("Liquidity Time")}</div>
          <div>{t("Withdraw Fee")}</div>
        </StyledHeader>
        <StyledContent>
          <div>{"<7 days"}</div>
          <div>{"0.5%"}</div>
          <div>{"7-14 days"}</div>
          <div>{"0.25%"}</div>
          <div>{"14-28 days"}</div>
          <div>{"0.125%"}</div>
          <div>{">28 days"}</div>
          <div>{"0%"}</div>
        </StyledContent>
      </StyledTableContainer>
    </>
  );
}

const DepositModal: React.FC<DepositModalProps> = ({
  max,
  decimals,
  onConfirm,
  onDismiss,
  tokenName = "",
  inputMin,
  showDesc = false,
  showFee = false,
}) => {
  const [val, setVal] = useState("");
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(max, decimals ?? 18);
  }, [max, decimals]);

  const displayBalance = useMemo(() => {
    return getDisplayBalance(max, decimals);
  }, [max, decimals]);

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value);
    },
    [setVal]
  );

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance.toString());
  }, [fullBalance, setVal]);

  const canConfirm = typeof inputMin === "undefined" || val >= inputMin;

  // console.log('val', val);
  // console.log('!val', !val);
  // console.log('!canConfirm', !canConfirm);
  // console.log('!canConfirm || !val', !canConfirm || !val);

  const title = `${t("Deposit")} ${tokenName}`;

  return (
    <StyledContainer>
      <Modal
        title={title}
        onDismiss={onDismiss}
        size="sm"
        style={isMobile ? { width: 340 } : undefined}
      >
        <TokenInput
          value={val}
          onSelectMax={handleSelectMax}
          onChange={handleChange}
          max={displayBalance}
          symbol={tokenName}
          min={inputMin}
        />
        <ModalActions>
          <ParallelogramButton
            disabled={!canConfirm || !val}
            onClick={() => onConfirm(val)}
          >
            {t("Deposit")}
          </ParallelogramButton>
        </ModalActions>

        {(showDesc || showFee) && <StyledDivider className="mt-30" />}
        {showDesc && (
          <ol>
            <li>{t("rewardsTips1")}</li>
            <li>{t("rewardsTips2")}</li>
          </ol>
        )}
        {showFee && <FeesTable />}
      </Modal>
    </StyledContainer>
  );
};

export default DepositModal;

const StyledContainer = styled.div`
  max-width: 560px;
  min-width: 450px;
`;

const StyledDivider = styled.div`
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const StyledTableContainer = styled.div`
  background-color: #26221f;
  border-radius: 1rem;
  line-height: 1.4;
  border: 1px solid ${(props) => props.theme.color.border};
  background-color: #fffbdd;
  overflow-y: hidden;
`;

const StyledGridBase = styled.div`
  display: grid;
  grid-template-columns: 40% 1fr;
  text-align: center;
  > div:nth-child(odd) {
    border-right: 1px solid ${(props) => props.theme.color.border};
  }
  > div {
    height: 1.3rem;
    padding: 4px;
  }
`;

const StyledHeader = styled(StyledGridBase)`
  padding: 3px 0;
  background-color: #fedb8d;
`;

const StyledContent = styled(StyledGridBase)`
  padding: 5px 0;
  border-top: 1px solid ${(props) => props.theme.color.border};
`;
