import { useCallback, useContext } from "react";
// import styled from "styled-components";

import { Context } from "../contexts/Modals";
import TxConfirmModal, { txStatus } from "../components/TxConfirmModal";

export default function useTxModal() {
  const { onTxModalPresent, onTxModalDismiss } = useContext(Context);

  const open = useCallback(
    (props: { status?: txStatus }) => {
      const { status } = props;
      onTxModalPresent(
        <TxConfirmModal onDismiss={onTxModalDismiss} status={status} />
      );
    },
    [onTxModalPresent, onTxModalDismiss]
  );

  const showTxModal = useCallback(
    (status?: txStatus, options?: any) => {
      console.log("open status", status);
      if (status) {
        open({ status });
      } else {
        open({ status: "pending" });
      }
    },
    [open]
  );
  return showTxModal;
}
