import { useEffect, FC } from "react";
import styled from "styled-components";
import { change, removeZoom } from "src/utils/changeZoom";
import veinR from "src/images/vein-right.png";

const Page: FC = ({ children }) => {
  useEffect(() => {
    window.addEventListener("resize", change);
    change();
    return () => {
      window.removeEventListener("resize", change);
      removeZoom();
    };
  }, []);
  return (
    <StyledContainer>
      {children}
      <StyledImg src={veinR} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  padding-top: 5.5rem;
  padding-left: 15rem;
  box-sizing: border-box;
  overflow: hidden;
  & > * {
    box-sizing: border-box;
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding-left: 202px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding-top: 6rem;
    padding-left: 0;
    padding-bottom: 3rem;
  `};
`;

const StyledImg = styled.img`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(25%, -50%) scale(1.4);
  z-index: -10;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    transform: translate(35%,-45%);
  `}
`;

export default Page;
