import { useCallback } from "react";
import { BigNumber } from "ethers";

import useTiny from "./useTiny";
import useAutoFreshRequest from "./useAutoFreshRequest";
import { bn0 } from "src/utils";
import useLimitedInterval from "./useLimitedInterval";

const cache: {
  pendingReward: BigNumber;
} = {
  pendingReward: bn0,
};

const useLPFarmPendingReward = (): [BigNumber | undefined, () => void] => {
  const tiny = useTiny();
  const account = tiny.myAccount;
  const farm = tiny.contracts.TinyLPFarm;

  const getUserInfo = useCallback(
    async (account: string | undefined) => {
      if (!account) return;
      // console.log("Squad tiny.myAccount", tiny.myAccount);
      const pendingReward: BigNumber = await farm.pendingTinyToken(account);
      // console.log("pendingReward res", pendingReward);
      cache.pendingReward = pendingReward;
      return pendingReward;
    },
    [farm]
  );

  const [pendingReward, refresh] = useAutoFreshRequest(getUserInfo, [account], {
    initState: cache.pendingReward,
  });
  const startRefresh = useLimitedInterval(refresh);

  return [pendingReward, startRefresh];
};

export default useLPFarmPendingReward;
