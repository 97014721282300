import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Modal, { ModalProps } from "../Modal";
import ParallelogramButton from "../Button/ParallelogramButton";

import txLoadingPng from "src/images/tx/loading.png";
import confirmPng from "src/images/tx/confirm.png";
import errorPng from "src/images/tx/error.png";
import walkPng from "src/images/tx/walk.png";

export type txStatus = "pending" | "error" | "confirm" | "cancel";

interface Props extends ModalProps {
  status?: txStatus;
  title?: string;
  message?: string;
}
export default function TxConfirmModal(props: Props) {
  const { onDismiss, status, title, message } = props;
  console.log("TxConfirmModal props", props);
  const { t } = useTranslation();
  const [obj, setObj] = useState({
    color: "#A26511",
    imgTip: (
      <div style={{ height: 154 }}>
        <RotateImg height="151" src={txLoadingPng} alt="" />
        <WalkImg src={walkPng} alt="loading" height={91} />
      </div>
    ),
    title: t("Confirm Transaction"),
    description1: t("Waiting for confirmation"),
  });

  useEffect(() => {
    switch (status) {
      case "error":
        setObj((pre) => {
          return {
            ...pre,
            color: "#DD0022",
            imgTip: <img src={errorPng} alt="" />,
          };
        });
        break;
      case "cancel":
        setObj((pre) => {
          return {
            ...pre,
            color: "#DD0022",
            imgTip: <img src={errorPng} alt="" />,
            description1: t("Transaction rejected"),
          };
        });
        break;
      case "confirm":
        setObj((pre) => {
          return {
            ...pre,
            color: "#169A23",
            imgTip: <img src={confirmPng} alt="" />,
            description1: t("Confirmed"),
          };
        });
        break;
      default:
        break;
    }
  }, [status, t]);

  return (
    <Modal
      contentClx="text-center"
      size="es"
      isConfirmModal={true}
      onDismiss={onDismiss}
      title={<StyledTitle color={obj.color}>{title || obj.title}</StyledTitle>}
    >
      <StyledContentBox>{obj.imgTip}</StyledContentBox>
      <div className="special-ff rfs-13 mt-20">{obj.description1}</div>
      {status === "pending" ? (
        <StyledDescription>{t("ConfirmInWallet")}</StyledDescription>
      ) : (
        <div className="mb-20" />
      )}

      <div className={status === "pending" ? "hidden" : ""}>
        <ParallelogramButton
          // size="sm"
          onClick={onDismiss}
        >
          {t("Close")}
        </ParallelogramButton>
      </div>
    </Modal>
  );
}

const StyledTitle = styled.span<{ color: string }>`
  color: ${(props) => props.color};
`;

const StyledContentBox = styled.div`
  position: relative;
  margin: 0 auto;
  ${(props) => props.theme.mediaWidth.upToSmall`
    transform: scale(0.8);
  `}
`;

// Here we create a component that will rotate everything we pass in over two seconds
const RotateImg = styled.img`
  animation: rotate 2s linear infinite;
`;

const WalkImg = styled.img`
  position: absolute;
  left: 55%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledDescription = styled.div`
  margin: 15px auto;
  width: 65%;
`;
