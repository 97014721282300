import { ButtonHTMLAttributes, PropsWithChildren } from "react";
import styled from "styled-components";
import clsx from "clsx"
import { formatFontSize } from "../../utils";

export interface BaseProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  width?: number | string;
  height?: number;
  size?: "md" | "sm";
  fontSize?: number | string;
}

export default function BaseButton(props: PropsWithChildren<BaseProps>) {
  const { children, className, width, height, size = "md", fontSize, ...rest } = props;
  return (
    <StyledBaseButton
      className={clsx(className, size)}
      width={width}
      height={height}
      type="button"
      fontSize={formatFontSize(fontSize)}
      {...rest}
    >
      {children}
    </StyledBaseButton>
  );
}

interface BaseStyledProps {
  width?: number | string;
  height?: number;
  fontSize?: string;
  disabled?: boolean;
}

export const StyledBaseButton = styled.button<BaseStyledProps>`
  &.md {
    height: 2.1rem;
    font-size: 1.05rem;
  }
  &.sm {
    height: 28px;
    font-size: 14px;
  }
  padding: 5px 15px;
  width: ${(props) => props.width ? formatFontSize(props.width) : "auto"};
  height: ${(props) => props.height ? formatFontSize(props.height) : "auto"};
  font-size: ${(props) => props.fontSize};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 28px;
    font-size: 1rem;
  `}
`;
