export type ChainId = 56 | 97;

export type Configuration = {
  chainId: ChainId;
  scanUrl: string;
  defaultProvider: string;
  // defaultProvider: Ethereum | string;
  externalTokens: { [contractName: string]: string };
  refreshInterval: number;
  gasLimitMultiplier: number;
  lpFarmTINCPerDay: number;
  nftFarmTINCPerDay: number;
  chargeAddress: string;
  deployments: any;
  gameApi: string;
};

export const chainId = Number(process.env.REACT_APP_CHAIN_ID) as ChainId;

console.log("chainId", chainId);

const providerMap = {
  97: "https://data-seed-prebsc-2-s2.binance.org:8545/",
  56: "https://bsc-dataseed.binance.org/",
};

const externalTokenMap = {
  97: {
    BNB: "0x485813Ea110C62CDd6A6a47854E0C4Dd1aC51519",
    TINC: "0xa1122A74240d799a21C1B2e6cBD60Fb2c29B10FF",
    BUSD: "0xB7486B62B16aCd1e688693d1486Cc81D750010F5", // 测试用 BUSD
    USDT: "0xD9FB2B16B7D93f8EC0CDfFe07c98281ac3bBF11D",
    LP: "0xDaf859F41a4473BA37B05BB55Ba386122011701B",
    "TINC-BUSD LP": "0xDaf859F41a4473BA37B05BB55Ba386122011701B",
    "BUSD-USDT LP": "0x1925c7552561017395e8fc366a2dbb80f13e9694",
    "BNB-BUSD LP": "0xd70a868971CeeAf1de5aE77D7aD789974991d208",
  },
  56: {
    BNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    TINC: "0xD28375a2438c06E5Ba1142878A93F037F0b4d2D1",
    BUSD: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    USDT: "0x55d398326f99059fF775485246999027B3197955",
    LP: "0x7EFaEf62fDdCCa950418312c6C91Aef321375A00",
    "BUSD-USDT LP": "0x7EFaEf62fDdCCa950418312c6C91Aef321375A00",
  },
};

const chargeAddressMap = {
  97: "0xEeB1E8Fcd51a6D4E720efD25dEf0d95cbD789bdc",
  56: "0x88296914e58457A92cB117ca17b7DE769E57E386",
};

const gameApiMap = {
  56: "https://tkapi.tinyworlds.io/api/sign-in/internal/",
  97: "https://tkapi-test.tinyworlds.io/api/sign-in/internal/",
};

const deployments =
  chainId === 97
    ? require("./tiny/abis/deployments.bsct.json")
    : require("./tiny/abis/deployments.fe.bscm.json");

const config: Configuration = {
  chainId: chainId,
  // defaultProvider: window.ethereum || providerMap[chainId],
  defaultProvider: providerMap[chainId],
  scanUrl:
    chainId === 56 ? "https://bscscan.com/" : "https://testnet.bscscan.com/",
  externalTokens: externalTokenMap[chainId],
  refreshInterval: 10000,
  gasLimitMultiplier: 1.2,
  lpFarmTINCPerDay: 200061,
  nftFarmTINCPerDay: 5004,
  chargeAddress: chargeAddressMap[chainId],
  deployments,
  gameApi: gameApiMap[chainId],
};

// console.log("config", config);

export type nftType = 1 | 2; // 2 目前不会用到，是预留用，不确定之后会不会用到

export type gradeType = 1 | 2 | 3 | 4 | 5 | 6; // hero 有 6 个档次

export type heroRole = 1 | 2 | 3; // hero 有 3 种职业，1:战士 2:游侠 3:法师

// 游戏的基础属性
export type propSymbol = "attack" | "defense" | "hp" | "luck";
export type propGrowSymbol = "gattack" | "gdefense" | "ghp" | "gluck";
export type exploreSymbol = "exploreTime" | "drop" | "crystal" | "drop2";

// 英雄拥有的属性排序
export const heroPropNameSort: propSymbol[] = [
  "attack",
  "defense",
  "hp",
  "luck",
];

// NFT 可以筛选的属性
export enum filterKeyEnum {
  grade = "grade",
  role = "role",
}

interface FilterItemGradeEnum {
  key: filterKeyEnum.grade;
  value: gradeType | undefined;
}
interface FilterItemClassEnum {
  key: filterKeyEnum.role;
  value: heroRole | undefined;
}

export type filtersType = (FilterItemGradeEnum | FilterItemClassEnum)[];

// NFT 可以排序的属性

export enum SortOrder {
  DESC = "DESC",
  ASC = "ASC",
}

export enum SortKey {
  grade = "grade",
  price = "price",
  startAt = "startAt",
  power = "power",
  level = "level",
}

export interface SortInfo {
  key: SortKey;
  order: SortOrder;
}

export default config;

// 游戏稀有度等级对应的称号
export const gradeTitle: {
  [key in gradeType]: string;
} = {
  1: "normal",
  2: "rare",
  3: "unique",
  4: "legendary",
  5: "epic",
  6: "supergod",
};

export const jobKey: {
  [key in heroRole]: string;
} = {
  1: "Warrior",
  2: "Ranger",
  3: "Wizard",
};

// 英雄最大等级
// nftlogic.setMaxLevel([1,2,3,4,5], [8,8,8,20,20]);
export const heroMaxLevelMap = {
  1: 8,
  2: 8,
  3: 8,
  4: 20,
  5: 20,
  6: 20,
};

export const heroGradeTitle: {
  [key in gradeType]: string;
} = {
  1: "N",
  2: "R",
  3: "SR",
  4: "SSR",
  5: "SSSR",
  6: "UR",
};

const gradeDurabilityMap = {
  56: {
    1: 60,
    2: 90,
    3: 120,
    4: 180,
    5: 210,
    6: 240,
  },
  97: {
    1: 60,
    2: 90,
    3: 120,
    4: 180,
    5: 210,
    6: 240,
  },
};

export const gradeDurability: {
  [key in gradeType]: number;
} = gradeDurabilityMap[chainId];

export const staminaCoefficient = 1;

const rewardList = [750, 500, 300, 200, 150, 100, 50, 30, 20];
export const getReward = (ranking: number) => {
  if (ranking === 1) {
    return rewardList[0];
  } else if (ranking === 2) {
    return rewardList[1];
  } else if (ranking === 3) {
    return rewardList[2];
  } else if (ranking === 4) {
    return rewardList[3];
  } else if (ranking === 5) {
    return rewardList[4];
  } else if (ranking <= 10) {
    return rewardList[5];
  } else if (ranking <= 30) {
    return rewardList[6];
  } else if (ranking <= 50) {
    return rewardList[7];
  }
  return rewardList[8];
};
