import { useCallback, useState } from "react";
import useAutoFreshRequest from "./useAutoFreshRequest";
import useTiny from "./useTiny";
import useLimitedInterval from "./useLimitedInterval";
// import useLPFarmUserInfo from "./useLPFarmUserInfo";
// import useLPFarmPendingReward from "./useLPFarmPendingReward";
// import useDataCache from './useDataCache';

// import {
//   getYieldFarmingContract,
// } from "../tiny/utils";
import { bn0 } from "src/utils";
import type { BigNumber } from "ethers";

// const cache = {
//   lpStaked: bn0
// }

function useLPStaked(): [BigNumber, () => void] {
  const tiny = useTiny();
  const strat = tiny.contracts.StratPCS;
  // const [cacheData, setCache] = useDataCache("LP_FARM_STAKED", sessionStorage)

  // const yieldFarmingContract = getYieldFarmingContract(tiny);

  // const [balance, setBalance] = useState(cache.lpStaked);

  // const multicallContract = getMulticallContract(tiny)

  // const [lpFarmUserInfo] = useLPFarmUserInfo();
  // const [lpFarmPendingReward] = useLPFarmPendingReward();

  const fetchStakedValue = useCallback(async () => {
    const wantLockedTotal: BigNumber = await strat.wantLockedTotal();
    // console.log("wantLockedTotal", wantLockedTotal);
    return wantLockedTotal;
  }, [strat]);

  const [lpFarmBalances, refresh] = useAutoFreshRequest(fetchStakedValue, [], {
    initState: bn0,
  });

  const startRefresh = useLimitedInterval(refresh);

  return [lpFarmBalances, startRefresh];
}

export default useLPStaked;
