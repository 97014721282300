import React, { createContext, useEffect } from "react";
import { useWallet } from "src/use-wallet/index";
import Tiny from "src/tiny";
import useTokenBalance from "src/hooks/useTokenBalance";
import config from "src/config";
import { bn0, noFunc } from "src/utils";
import type { BigNumber } from "ethers";

export interface TinyContext {
  tiny: Tiny;
  tincBalance: BigNumber;
  freshTINCBalance: () => void;
}

export const tiny = new Tiny(config);

export const Context = createContext<TinyContext>({
  tiny,
  tincBalance: bn0,
  freshTINCBalance: noFunc,
});

export const TinyProvider: React.FC = ({ children }) => {
  const { ethereum, account } = useWallet();

  useEffect(() => {
    if (account) {
      // tiny.unlockWallet(ethereum, "0x6AcCb3ECa94562416aAcCAd044241bA91De61b7f");
      tiny.unlockWallet(ethereum, account);
    }
  }, [account]);

  const TINC = tiny.externalTokens.TINC;

  const [tincBalance, freshTINCBalance] = useTokenBalance(TINC, account);

  return (
    <Context.Provider value={{ tiny, tincBalance, freshTINCBalance }}>
      {children}
    </Context.Provider>
  );
};
