// import { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
// import { useTranslation } from "react-i18next";

import useFarmPrice from "src/hooks/useFarmPrice";
// import useTiny from "src/hooks/useTiny";

import logoPng from "src/images/logo.png";
// import Button7 from "src/images/Button_7.png";
// import userPng from "src/images/icon_user.png";
import jiantou from "./jiantou.png";

export default function Info() {
  // const tiny = useTiny();
  const [price] = useFarmPrice();
  // const { t } = useTranslation();
  return (
    <StyledInfo>
      <StyledLogo src={logoPng} alt="" />
      {/* <StyledLine src={linePng} alt="" /> */}
      {/* <StyledBalance>
        <StyledBuyText>BUY TINC</StyledBuyText>
        <img className="mr-5" width={20} src="/images/tokens/TINC.png" alt="TINC" />
        <StyledPrice className="mr-5">${price["TINC"].toFixed(1)}</StyledPrice>
      </StyledBalance> */}
      <StyledArrow src={jiantou} alt="" />
    </StyledInfo>
  );
}

const StyledInfo = styled.div`
  position: absolute;
  top: 0;
  padding: 0px 20px 0px;
  background: linear-gradient(#f9be3a, #f79f0e);
  text-align: center;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;

const StyledLogo = styled.img`
  /* margin: 20px 5px 15px;
  width: 75%; */
  margin: 12px 5px 8px;
  width: 55%;
  /* @media (max-width: 1550px) {
  } */
`;

const StyledBalance = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px auto 0;
  padding: 6px;
  color: ${(props) => props.theme.color.yellow};
  font-weight: 600;
  background-color: #905419;
  border: 2px solid #fdce4f;
  border-radius: 36px;
`;

const StyledBuyText = styled.span`
  margin-right: 0.5rem;
  flex-grow: 1;
`;

const StyledPrice = styled.span`
  padding-bottom: 1px;
  /* font-family: ${(props) => props.theme.fontFamily.game}; */
  color: white;
  font-size: 1.1rem;
`;

const StyledArrow = styled.img`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 35%;
`;
