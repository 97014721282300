import React from "react";
import styled from "styled-components";

const CardContent: React.FC<{ className?: string }> = ({
  children,
  className,
}) => <StyledCardContent className={className}>{children}</StyledCardContent>;

export const StyledCardContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing[4]}px 2.4rem;
  box-sizing: border-box;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: ${theme.spacing[3]}px 0.4rem;
  `};
`;

export default CardContent;
