export const white = "#FFF";
export const black = "#000";

export const green = {
  300: "#56F24F",
  500: "#00D110",
};

export const blue = {
  300: "#5eabc9",
  400: "#28DEFF",
  500: "#1c5f79",
  600: "#161F37", // bank 卡片样式
  700: "#063648",
  800: "#152239", // 首页卡片样式
};

export const purple = {
  200: "#D556FF",
  400: "#5A206E",
  600: "#8E35AC",
  800: "#2C1234",
};

export const red = {
  100: "#CC453A",
  200: "#893730",
  700: "#1F1A19",
};

export const grey = {
  100: "#f8f8f8",
  300: "#eaeaea",
  400: "#B4B4B4",
  500: "#9a9a9a",
  700: "#5C5C5C",
  800: "#343333",
  900: "#2F2F2F",
};

export const bg = "#000000";

export const teal = {
  200: "#64ffda",
};
