import config from "../config";
// const chainId = 1337
const chainId = config.chainId;

// HETH/HT    3X
// HUSD/HT    3X
// HLTC/HT    3X

// const tokens = config.externalTokens;

export interface BasePoolInfo {
  pid: number;
  lpAddress?: string; // 如果是 LP 就会有 lpAddress 字段
  name: string; // 是用来在前端展示的
  tokenSymbol: string;
  quoteTokenSymbol?: string; // 如果是 LP 就会有 quoteTokenSymbol 字段
  multiplying: number;
  icon: string;
}

const poolMap = {
  97: [
    {
      pid: -1,
      name: "TINC-BUSD Pool",
      tokenSymbol: "TINC-BUSD LP",
      multiplying: 1,
      icon: "/images/tokens/BUSD.png",
    },
    {
      pid: 0,
      lpAddress: config.externalTokens["BUSD-USDT LP"],
      name: "BUSD-USDT LP",
      tokenSymbol: "BUSD-USDT LP",
      quoteTokenSymbol: "BUSD",
      multiplying: 1,
      icon: "/images/tokens/BNB.png",
    },
  ],
  56: [
    // {
    //   pid: -1,
    //   name: "TINC-BNB Pool",
    //   tokenSymbol: "TINC-BNB LP",
    //   multiplying: 1,
    //   icon: "/images/tokens/BNB.png",
    // },
    {
      pid: 0,
      lpAddress: config.externalTokens["BUSD-USDT LP"],
      name: "BUSD-USDT LP",
      tokenSymbol: "BUSD-USDT LP",
      quoteTokenSymbol: "BUSD",
      multiplying: 1,
      icon: "/images/tokens/BNB.png",
    },
  ],
};

export const supportedPools: BasePoolInfo[] = poolMap[chainId];
