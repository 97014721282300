import { useCallback, useContext, DependencyList } from "react";

import { Context } from "../contexts/Modals";

const useModal = <T = any>(
  modal: React.ReactNode,
  dependentList: DependencyList = []
): [(props?: T) => void, () => void] => {
  const { onPresent, onDismiss } = useContext(Context);

  // 如果把 modal 添加到依赖项里，会导致 modal 每一次都在变化
  const handlePresent = useCallback(
    (props?: T) => {
      console.log(Date.now());
      // console.log('modal', modal);
      onPresent(modal, props);
    },
    [onPresent, ...dependentList]
  );

  return [handlePresent, onDismiss];
};

export default useModal;
