import { lazy, Suspense, useEffect, useMemo, FC } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { UseWalletProvider, useWallet } from "src/use-wallet/index";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";

import store from "./state";
import Updaters from "./state/Updaters";
import theme from "./theme";
import ModalsProvider from "./contexts/Modals";
import TinyProvider from "./contexts/TinyProvider";
import FarmsProvider from "./contexts/Farms";
// import useInGameTestList from "src/hooks/useInGameTestList";

import Home from "./views/Home";
import Popups from "./components/Popups";
import Page from "./components/Page";
import ErrorBoundary from "./components/ErrorBoundary";
import Topbar from "./views/PageComponents/Topbar";
import BottomBar from "./views/PageComponents/BottomBar";
import Loader from "./components/Loader";
// import { deleteAT } from "./utils/storage";
import { getDefaultProvider } from "./utils/provider";
import { chainId } from "./config";

import "./App.css";
import Drawer from "./components/Drawer";
import GamePage from "./views/Game/GamePage";
import useModal from "./hooks/useModal";
import BetaNoticeModal from "./components/BetaModal";
import BetaNotification from "./components/BetaHeader";
import { useBlockNumber } from "./state/application/hooks";

const MyNFT = lazy(() => import("./views/MyNFT"));
const Mine = lazy(() => import("./views/Mine"));
const Blind = lazy(() => import("./views/Blind"));
const MarketPlace = lazy(() => import("./views/MarketPlace"));
const Game = lazy(() => import("./views/Game"));
const Rank = lazy(() => import("./views/Rank"));

// Create a client
const queryClient = new QueryClient();

function Routers() {
  const { connect, account } = useWallet();
  // console.log("Routers account", account);
  // console.log("error", error);
  const [showBetaNotice] = useModal(<BetaNoticeModal />);
  useEffect(() => {
    connect("injected");
    if (chainId === 56) {
      showBetaNotice();
    }
  }, []);

  const blockNumber = useBlockNumber()

  useEffect(() => {
    if (blockNumber === 15530450 || blockNumber === 15530451) {
      showBetaNotice()
    }
  }, [blockNumber])

  const gameUrl = localStorage.getItem("gameUrl");

  const paths = useMemo(() => {
    return [
      "/home",
      "/mynfts",
      "/tinyvault",
      "/mysterybox",
      "/marketplace",
      "/game",
      "/rankings",
    ];
  }, []);

  return (
    <ErrorBoundary account={account}>
      <BetaNotification />
      <Suspense fallback={null}>
        <Switch>
          <Route path={paths}>
            <Page>
              <Topbar />
              <Suspense fallback={<Loader />}>
                <Switch>
                  <Route path="/home">
                    <Home />
                  </Route>
                  <Route path="/tinyvault">
                    <Mine />
                  </Route>
                  <Route path="/mysterybox">
                    <Blind />
                  </Route>
                  <Route path="/mynfts">
                    <MyNFT />
                  </Route>
                  <Route path="/marketplace">
                    <MarketPlace />
                  </Route>
                  <Route path="/game">
                    <Game />
                  </Route>
                  <Route path="/rankings">
                    <Rank />
                  </Route>
                </Switch>
              </Suspense>
              <Drawer />
              <BottomBar />
            </Page>
          </Route>
          <Route path={"/tinykingdom"}>
            {gameUrl ? <GamePage gameSrc={JSON.parse(gameUrl)} /> : <Loader />}
          </Route>
          <Route children={<Redirect to="/home" />} />
        </Switch>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </Suspense>
    </ErrorBoundary>
  );
}

const App: FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <UseWalletProvider
          connectors={{
            provided: {
              provider: getDefaultProvider(),
            },
          }}
        >
          <Updaters />
          <TinyProvider>
            <Router>
              <QueryClientProvider client={queryClient}>
                <FarmsProvider>
                  <ModalsProvider>
                    <Popups />
                    <Routers />
                  </ModalsProvider>
                </FarmsProvider>
              </QueryClientProvider>
            </Router>
          </TinyProvider>
        </UseWalletProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
