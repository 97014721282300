import { memo, useEffect, useState } from "react";
import styled from "styled-components";
// import { detect } from "detect-browser";
import { useWallet } from "src/use-wallet/index";

import PageContent from "../../components/PageContent";
import Container from "src/components/Container";
// import Statistics from "./Statistics";
// import Detail from "./Detail";

import personImg from "../../images/home_person.png";
import Carousel from "./Carousel";
import Banner from "./Banner";
import { CSSTransition, SwitchTransition } from "react-transition-group";

// const browser = detect();

function Home() {
  const { account, error } = useWallet();
  const [showAnime, setShowAnime] = useState(false);

  useEffect(() => {
    setShowAnime(true);
  }, []);

  return (
    <PageContent>
      {/* <div>{browser?.name}</div>
      <div>{window.devicePixelRatio}</div> */}
      {/* <div>{navigator.language}</div> */}
      <CSSTransition in={showAnime} classNames="fade" timeout={500}>
        <Container size="lg">
          <Banner />
          <Carousel />
        </Container>
      </CSSTransition>
    </PageContent>
  );
}

export default memo(Home);

const StyledDesc = styled.div`
  display: flex;
`;

const StyledPersonImgBox = styled.div`
  position: relative;
  width: 30%;
  max-width: 246px;
  min-width: 150px;
`;

const StyledPersonImg = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0;
`;
