import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Context as FarmsContext } from "src/contexts/Farms";

import diImg from "src/images/di_2.png";
import type { PoolStakeInfo } from "src/services/index";
import { GoButton } from "../Mine/YieldFarming/components/FarmCard";

interface Props {
  totalVolume: string;
}

export default function EconomyInfo({ totalVolume }: Props) {
  const { lpFarmNFTPowerInfo, stakeInfos, calcApyByBonus } =
    useContext(FarmsContext);
  const pool = lpFarmNFTPowerInfo.pool;
  const { t } = useTranslation();

  const maxAPYGlobal = useMemo(() => {
    const maxAPYLP = calcApyByBonus(pool.max_bonus + 1000);
    if (stakeInfos) {
      const list: PoolStakeInfo[] = Object.values(stakeInfos);
      const maxApyStaking = Math.max(
        ...list.filter((ele) => ele.pid > -1).map((ele) => ele.baseApy)
      );

      return Math.max(maxAPYLP, maxApyStaking || 0).toFixed();
    }
    return maxAPYLP.toFixed();
  }, [calcApyByBonus, pool.max_bonus, stakeInfos]);

  return (
    <StyledContainer>
      <StyledTINCInfo className="shadow">
        <StyledTINCItem>
          <span>{t("TINC In Circulation")}</span>
          <span className="ff-black">$ {"--"}</span>
        </StyledTINCItem>
        <StyledTINCItem>
          <span>{t("Total NFT Trading Volume")}</span>
          <span className="ff-black">$ {totalVolume}</span>
        </StyledTINCItem>
        <StyledTINCItem>
          <span>{t("TINC Burned")}</span>
          <span className="ff-black">$ {"--"}</span>
        </StyledTINCItem>
      </StyledTINCInfo>
      <StyledContent className="shadow">
        <span className="display-flex align-center">
          {t("Earn up to")}&nbsp;&nbsp;&nbsp;&nbsp;
            <span className="white text-outline rfs-18 ff-black">
              {maxAPYGlobal}%
            </span>
            &nbsp;&nbsp;APR
        </span>
        <GoButton height={29} />
      </StyledContent>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
  `}
`;

const StyledTINCInfo = styled.div`
  background: #fca948;
  color: white;
  padding: 10px 10px;
  display: flex;
  gap: 80px;
  font-size: 1rem;
  border-radius: 5px;
  width: 67%;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: calc(100% - 20px);
    gap: 10px;
    flex-wrap: wrap;
  `}
`;

const StyledTINCItem = styled.div`
  border-left: 3px solid white;
  padding-left: 5px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const StyledContent = styled.div`
  width: calc(33% - 12px);
  box-shadow: ${(props) => props.theme.boxShadow};
  background-image: url(${diImg});
  margin-left: 12px;
  display: flex;
  font-size: 1rem;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
    height: 57px;
    margin-left: 0;
    margin-top: 10px;
  `}
`;
