import styled from "styled-components";
import { memo } from "react";

import Nav from "./Nav/index";
import Info from "./Info/index";
import Bottom from "./Bottom";

function SideBar() {
  return (
    <StyledContainer>
      <Info />
      <Nav />
      <Bottom />
    </StyledContainer>
  );
}

export default memo(SideBar);

const StyledContainer = styled.aside`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 15rem;
  background-image: linear-gradient(#fcca42, #fdd456);
  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 202px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;
