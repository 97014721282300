import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { useLocation } from "react-router";

import useModal from "src/hooks/useModal";
import useIsMobile from "src/hooks/useIsMobile";
import BetaNoticeModal from "../BetaModal";
import QuestionIcon from "../QuestionIcon";

export default function BetaNotification() {
  const { t } = useTranslation();
  const [showBetaNotice] = useModal(<BetaNoticeModal />);
  const location = useLocation();
  const isMobile = useIsMobile();
  const isGame = location.pathname.includes("tinykingdom");
  const isHome = location.pathname.includes("home");
  // const isRanking = location.pathname.includes("rankings")
  // console.log('location.pathname', location.pathname);
  // console.log("isGame", isGame);
  if (isMobile && isHome) {
    return null;
  }
  return (
    <StyledContent isGame={isGame} isHome={isHome}>
      <h2
        style={{ color: "#FF4500" }}
        className="display-flex flex-center text-center"
      >
        <span style={{ whiteSpace: "nowrap" }}>{t("open beta test")} </span>
        <QuestionIcon className="ml-10" onClick={showBetaNotice} />
      </h2>
      {!isGame && (
        <StyledTime className="text-center">
          15/02/22 8:00AM UTC - 24/02/22 8:00AM UTC
        </StyledTime>
      )}
    </StyledContent>
  );
}

const StyledContent = styled.div<{ isGame: boolean; isHome: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 10px;
  ${(props) =>
    props.isGame
      ? css`
          right: 15px;
        `
      : css`
          left: calc(50% + 7.5rem);
          transform: translateX(-50%);
        `};

  z-index: 1001;

  > h2 {
    margin: 0;
  }

  ${({ theme, isGame, isHome }) => theme.mediaWidth.upToExtraSmall`
    top: ${isGame ? 0 : 10}rem;
    left: ${isGame ? "auto" : "50%"};
    display: ${isHome ? "none" : "flex"};
  `};
`;

const StyledTime = styled.h2`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;
