import { useCallback, useMemo } from "react";
import useAutoFreshRequest from "./useAutoFreshRequest";
import useTiny from "./useTiny";
import useLimitedInterval from "./useLimitedInterval";
import useLPFarmUserInfo from "./useLPFarmUserInfo";
import useLPFarmPendingReward from "./useLPFarmPendingReward";
import type { Farm } from "src/contexts/Farms/types";

import {
  getYieldFarmingContract,
  // getMulticallContract,
  getStaked,
} from "../tiny/utils";
import { bn0 } from "src/utils";
import { getEarned } from "../tiny/utils";
import type { StakedValue } from "src/contexts/Farms/types";

function useAllStakedValue(
  farms: Farm[]
): [StakedValue[] | undefined, () => void] {
  const tiny = useTiny();
  const account = tiny.myAccount;
  const yieldFarmingContract = getYieldFarmingContract(tiny);

  // const multicallContract = getMulticallContract(tiny)

  const [lpFarmUserInfo] = useLPFarmUserInfo();
  const [lpFarmPendingReward] = useLPFarmPendingReward();

  const fetchAllStakedValue = useCallback(
    async (account: string | undefined) => {
      if (!account) return;
      const promises = farms
        .filter((ele) => ele.pid > -1)
        .map(async (farm) => {
          const { pid } = farm;
          const stakedBalance = await getStaked(
            yieldFarmingContract,
            pid,
            account
          );
          const earned = await getEarned(yieldFarmingContract, pid, account);
          return {
            pid,
            stakedBalance,
            pendingReward: earned,
          };
        });
      const results = await Promise.all(promises);
      // console.log('results', results);
      return results;
    },
    [farms, yieldFarmingContract]
  );

  const [yieldFarmingBalances, refresh] = useAutoFreshRequest(
    fetchAllStakedValue,
    [account]
  );

  const startRefresh = useLimitedInterval(refresh);

  // 最后把 LPFarm 和 yieldFarming 的组合一下
  const balances = useMemo(() => {
    const lpFarmStakedValue = {
      pid: -1,
      stakedBalance: lpFarmUserInfo?.amount ?? bn0,
      pendingReward: lpFarmPendingReward ?? bn0,
    };
    if (yieldFarmingBalances) {
      return [lpFarmStakedValue].concat(yieldFarmingBalances);
    }
    return [lpFarmStakedValue];
  }, [lpFarmUserInfo, yieldFarmingBalances, lpFarmPendingReward]);

  // console.log("yieldFarmingBalances", yieldFarmingBalances);
  // console.log("balances", balances);

  return [balances, startRefresh];
}

export default useAllStakedValue;
