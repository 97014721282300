import { detect } from "detect-browser";
import { backend_url, static_api_base } from "../constants";

const browser = detect();

export function request<T = any>(
  path: string,
  options?: RequestInit
): Promise<T> {
  return fetch(backend_url + path, options).then((res) => res.json());
}

export function fileFetch(path: string, options?: RequestInit) {
  return fetch(backend_url + static_api_base + path, options);
}

export interface ErrorBody {
  account: string | null | undefined;
  error: string | null | undefined;
  type: "tx" | "components";
  [key: string]: string | number | null | undefined;
}
export function logErrorToService(body: ErrorBody) {
  // window.ethereum
  const account = window?.ethereum?.selectedAddress;
  const common: any = {
    pathname: window.location.pathname,
    os: browser?.os,
    browsername: browser?.name,
  };
  if (!body.account) {
    common.account = account;
  }
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  return request("/api/dev/error", {
    headers,
    body: JSON.stringify(Object.assign({}, body, common)),
    method: "POST",
  });
}
